import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
import { CONSTANTS } from 'src/app/shared/common/constants';
import { CategoriesService } from 'src/app/shared/services/categories/categories.service';
import { ConfigsService } from 'src/app/shared/services/configs/configs.service';
import { GaragesService } from 'src/app/shared/services/garages/garages.service';
import { PromotionsService } from 'src/app/shared/services/promotions/promotions.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
declare var $: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  location = { lat: Number, lng: Number };
  garagesNearBy: any = [];
  categories: any = [];
  categorySelected: any = {};
  categoryIdSelected = '';
  isLoading = false;
  banners: any = [];
  ROUTER = APP_ROUTES
  modalLogin = 'modal-login-header';
  modalLogout = 'modal-confirm-logout';
  constructor(
    private utilsService: UtilsService,
    private garagesService: GaragesService,
    private categoryService: CategoriesService,
    private router: Router,
    public usersService: UsersService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private promotionsService: PromotionsService
  ) { }

  ngOnInit(): void {
    this.initData()
  }
  async initData() {
    await this.getBanners();

    if (isPlatformBrowser(this.platformId)) {
      await Promise.all([
        this.getListCategory(),
        this.getGaragesNearBy(),
      ]);
    }
  }

  async getBanners() {
    try {
      this.isLoading = true;
      const result = await this.promotionsService.getPromotions('home').toPromise();

      this.banners = result && result.data || [];
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      this.utilsService.showError(error && error.message);
    }
  }

  async getListCategory() {
    try {
      const result = await this.categoryService.getListCategories('').toPromise();

      if (!result || !result.data) {
        return;
      }

      this.categories = result.data;
    } catch (error: any) {
      this.utilsService.showError(error && error.message);
    }
  }

  async getGaragesNearBy() {
    // skip on SSR
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    try {
      // this.isLoading = true;

      const location = await this.utilsService.getCurrentLocation();
      const result = await this.garagesService.getGaragesNearBy(
        location?.lat,
        location?.lng,
        0,
        this.categoryIdSelected
      ).toPromise();

      this.garagesNearBy = result && result.data && result.data.garages || [];
      // this.isLoading = false;
    } catch (error: any) {
      // this.isLoading = false;
      this.utilsService.showError("Can not get garages near by. Please try again later and make sure you allow location permission.");
    }
  }

  onSelectCategory(item: any) {
    this.categorySelected = item
    this.categoryIdSelected = item.id;

    return this.router.navigate([`garages`], {
      queryParams: { categoryId: item && item.key }
    });
  }

  onSelecteSlide(item: any) {
    if (item.linkToType == 'internal' && item.linkToValue) {
      if (item.linkToValue == 'home') {
        this.router.navigate(['/'])
      } else {
        this.router.navigateByUrl(APP_ROUTES.GARAGE + '?categoryId=' + item.linkToValue)
      }
    }

    if (item.linkToType == 'external' && item.linkToValue) {
      try {
        let newWin = window.open(item.linkToValue, '_blank');

        if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
          alert('Popup Blocker is enable. Please allow popups for this website!');
        }
      } catch (error: any) {
        return this.utilsService.showError(error.message);
      }
    }
  }

  onChooseGarage(item: any) {
    if (!this.categoryIdSelected) {
      this.categoryIdSelected = this.categories[0].id;
    }

    const category = this.categories[0]
    this.router.navigate([`garages/${item.key}`], {
      queryParams: { categoryId: category && category.key }
    })
  }

  pre() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft -= 250;
    }
  }

  next() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft += 250;
    }
  }

  onClickFindAllFAQ() {
    try {
      let newWin = window.open(`https://gocarhelp.zendesk.com/hc/en-us/sections/4403210234265-General`, '_blank');

      if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
        alert('Popup Blocker is enable. Please allow popups for this website!');
      }
    } catch (error: any) {
      return this.utilsService.showError(error.message);
    }
  }

  onClickMyBooking() {
    this.router.navigate([`/${APP_ROUTES.HISTORY}`]);
  }

  onClickLogin() {
    const userLogin = this.usersService.getUserLocal();
    if (userLogin) {
      return this.utilsService.showError('User Login already!');
    }

    this.showModal(this.modalLogin);

  }

  onClickLogout() {
    this.showModal(this.modalLogout);
  }


  confirmLogout(option: any) {
    if (option != 'ok') {
      return;
    }

    this.usersService.logout();

    // const url = this.router.url;

    // if (url === '/history') {
    //   this.router.navigate([APP_ROUTES.HOME]);
    // }
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }
}
