import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
import { GaragesService } from 'src/app/shared/services/garages/garages.service';
import { UploadFileService } from 'src/app/shared/services/upload-file/upload-file.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { environment as config } from '../../../environments/environment';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { Upload } from 'src/app/shared/models/upload';
import { CarsService } from 'src/app/shared/services/cars/cars.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

declare var grecaptcha: any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {
  MODAL_CONFIRM_SUBMIT_CONLSUTATION = 'model-confirm-submit-consultation';
  submitted = false;
  userInfoForm: FormGroup = new FormGroup({
    make: new FormControl(null, [Validators.required]),
    model: new FormControl(null, [Validators.required]),
    makeOther: new FormControl(''),
    modelOther: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    photo: new FormControl('')
  });
  carsMakeDefault: any = [];
  carsModelDefault: any = [];
  carSelected: any = {};
  siteKey = '';
  isLoading = false;
  ROUTER = APP_ROUTES;
  images: any = [];
  imagesUpload: any = [];
  isSubmittedSuccess: boolean = false;

  constructor(
    private garagesService: GaragesService,
    private carsService: CarsService,
    private utilsService: UtilsService,
    private uploadFile: UploadFileService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.initData()
  }

  ngOnInit(): void {
    this.siteKey = config.siteKeyCaptcha;
    const self = this;
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        grecaptcha.ready(function () {
          grecaptcha.render('captcha_consultation', {
            sitekey: self.siteKey,
            size: 'invisible'
          })
        })
      }
    }, 100);
  }

  async initData() {
    await this.getListCarType();

    this.refreshSelectPicker();
  }

  get form(): { [key: string]: AbstractControl } {
    return this.userInfoForm.controls;
  }

  selectCarMake(event: any) {
    let carMake = this.carsMakeDefault.find((e: any) => { return e.id == event.target.value })
    this.carsModelDefault = carMake && carMake.carModels || []

    let carModel = this.carsModelDefault.find((e: any) => e.id == this.carSelected.carModelId)
    if (!carModel) {
      this.carSelected.carModelId = null
      this.userInfoForm.patchValue({
        model: '',
        modelOther: ''
      })
    }

    if (this.userInfoForm.value.make == 'other') {
      this.userInfoForm.patchValue({
        model: 'other',
        modelOther: ''
      })
      this.userInfoForm.controls['makeOther'].setValidators([Validators.required])
      this.userInfoForm.controls['modelOther'].setValidators([Validators.required])
    } else {
      this.userInfoForm.controls['makeOther'].clearValidators()
      this.userInfoForm.controls['modelOther'].clearValidators()
    }

    $('.carmake').removeClass("is-invalid");
    this.refreshSelectPicker();
  }

  async getListCarType() {
    try {
      const result = await this.carsService.getListCarType().toPromise();
      this.carsMakeDefault = result.data || []
    } catch (error) {
      this.utilsService.showError("Load Cars Error. Please try again.");
    }
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  async submitConsultation() {
    this.submitted = true;
    if (this.userInfoForm.invalid) {
      return this.refreshSelectPicker();
    }

    this.showModal(this.MODAL_CONFIRM_SUBMIT_CONLSUTATION);
  }

  async confirmSubmitConsultation(option: any) {
    if (option !== 'ok') {
      return;
    }

    const self = this;
    grecaptcha.ready(function () {
      grecaptcha.execute({ action: 'consultation_page' }).then(async function (reCaptchaToken: any) {
        self.isLoading = true;
        try {
          self.isLoading = true;
          const param = self.userInfoForm.value;
          let promisses = [];

          for (let i = 0; i < self.imagesUpload.length; i++) {
            let attachment = self.imagesUpload[i];
            let path = `garage-website/images`;
            promisses.push(self.uploadFile.startUploadingImage(attachment.file, path));
          }
          let result = await Promise.all(promisses);
          param.attachments = result || [];

          await self.garagesService.getAQuote(param, reCaptchaToken).toPromise();
          self.utilsService.showSuccess('Submit Consultation success!');
          self.userInfoForm.reset();
          self.userInfoForm.clearAsyncValidators();
          self.images = [];
          self.imagesUpload = [];
          self.isLoading = false;
          self.submitted = false;

          self.refreshSelectPicker();
          self.router.navigate(['consultation/submit-success']);
        } catch (error: any) {
          self.isLoading = false;
          self.utilsService.showError("Cannot send please try again!");
        }
      })
    })
  }

  removeImage(i: any) {
    _.remove(this.images, (item, index) => { return index == i })
    _.remove(this.imagesUpload, (item, index) => { return index == i })
  }

  makeTrustedImage(item: any) {
    const imageString = JSON.stringify(item).replace(/\\n/g, '');
    const style = 'url(' + imageString + ')';
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }

  uploadImage() {
    document.getElementById('quote-image')?.click();
  }

  async attachImage(event: any) {
    let files = event.target && event.target.files || []
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.imagesUpload.push({ file: new Upload(files[i]) })
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.images.push((<FileReader>event.target).result)
        }
        reader.readAsDataURL(files[i]);
      }
    }
  }

  private refreshSelectPicker() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        $('.selectpicker').selectpicker('refresh');
      }
    });
  }

  selectCarModel(event: any) {
    if (!event || !event.target || !event.target.value) {
      return;
    }

    if (this.userInfoForm.value.model == 'other') {
      this.userInfoForm.patchValue({
        modelOther: ''
      })
      this.userInfoForm.controls['modelOther'].setValidators([Validators.required])
    } else {
      this.userInfoForm.controls['modelOther'].clearValidators()
    }

    $('.carmodel').removeClass("is-invalid");
    this.refreshSelectPicker();
  }

  backToHome() {
    this.router.navigate(['']);
  }
}
