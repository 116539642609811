<div class="nav-desktop">
  <a class="navbar-brand" routerLink="/">
    <img src="./assets/images/img-logo.png" width="124" height="58" alt="GoCar Logo" />
  </a>

  <div class="collapse navbar-collapse " id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <!-- <li class="nav-item" *ngFor="let item of menus">
        <a class="nav-link" href="{{item.href}}">{{item.name}}</a>
      </li> -->
    </ul>
    <div class="member">
      <button class="btn-login clickable ml-2" (click)="onClickLogin()" *ngIf="!usersService.getUserLocal()">
        Login <i class="fa fa-arrow-right ml-2"></i>
      </button>
      <button class="btn-login clickable" (click)="onClickMyBooking()" *ngIf="usersService.getUserLocal()">
        My Booking
      </button>
      <span class="l-text ml-3 clickable" (click)="onClickLogout()" *ngIf="usersService.getUserLocal()"><i
          class="fa fa-sign-out-alt" aria-hidden="true"></i></span>

    </div>
  </div>
</div>


<div class="phone-nav container p-0">
  <div class="phone">
    <div class="content d-flex">
      <!--button class="btn-login btn-book clickable" (click)="onClickBookApp()" *ngIf="isShowBookAppBtn()">
        Book an Appointment
      </button-->
      <nav role="navigation" class="align-self-center ml-1 mt-2">
        <div id="menuToggle">
          <input type="checkbox" [(ngModel)]="checked" onclick="event.stopPropagation();" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu" onclick="event.stopPropagation();">
            <li class="stick-item">
              <a class="nav-link" routerLink="/{{ROUTES.HOME}}" (click)="checked = !checked">Home</a>
            </li>
            <li class="stick-item" *ngFor="let item of STICK_MENU">
              <a class="nav-link" (click)="clickStickMenu(item); checked= !checked">{{item.name}}</a>
            </li>

            <!-- <li *ngFor="let item of menus">
              <a class="nav-link" routerLink="/{{item.href}}" (click)="checked = !checked">{{item.name}}</a>
            </li> -->
            <li *ngIf="usersService.getUserLocal()">
              <a class="nav-link" routerLink="/{{MYBOOKING.href}}" (click)="checked = !checked">
                {{MYBOOKING.name}}
              </a>
            </li>
            <li *ngIf="!usersService.getUserLocal()">
              <button class="btn-login clickable" (click)="onClickLogin(); checked = !checked">Login
                <i class="fa fa-arrow-right ml-2"></i>
              </button>
            </li>
            <li *ngIf="usersService.getUserLocal()"><button class="btn-login clickable"
                (click)="onClickLogout(); checked = !checked">Logout</button></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>