<app-header></app-header>
<section>
    <div class="container p60-top-bottom">
        <div class="row align-middle">

            <div class="col-12 text-center">
                <img style="width: 150px;" src="../../../../assets/images/img-404.png" alt="Not Found" />
                <h1 style="font-size: 62px;">404</h1>
                <h2>Page Not Found</h2>
                <p>We're sorry, the page you requested could not be found.<br />Please go back to the homepage</p>
                <a routerLink="/" class="btn-gradient">Go to Homepage</a>
            </div>
        </div>
    </div>
</section>