import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { environment as config } from '../../../../environments/environment';
import { CONSTANTS } from '../../common/constants';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {
  @Input() modalId = '';
  @Input() redirectUrl: any
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();


  num1: any;
  num2: any;
  num3: any;
  num4: any;
  num5: any;
  num6: any;

  isLoading = false;
  isRequestOTP = false;
  isEnable = false;
  phoneNumber = '';
  siteKey = '';

  constructor(
    private utilsService: UtilsService,
    private userService: UsersService,
    private toastService: ToastrService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  async getGreCaptcha(): Promise<any> {
    //ReferenceError: grecaptcha is not defined
    if (typeof grecaptcha === 'undefined') {
      console.log('wait for grecaptcha');
      await new Promise((resolve) => setTimeout(resolve, 100));
      return this.getGreCaptcha();
    }
    console.log('grecaptcha is ready');

    return grecaptcha;
  }

  ngOnInit() {
    this.siteKey = config.siteKeyCaptcha;
    const self = this;
    if (isPlatformBrowser(self.platformId)) {
      this.getGreCaptcha().then(() => {
        grecaptcha.ready(function () {
          grecaptcha.render('captcha_element', {
            sitekey: self.siteKey,
            size: 'invisible'
          })
        })
      })
    }
  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.phoneNumber = '';
    this.isRequestOTP = false;
    this.num1 = undefined;
    this.num2 = undefined;
    this.num3 = undefined;
    this.num4 = undefined;
    this.num5 = undefined;
    this.num6 = undefined;

    this.choose.emit(option == 'ok' ? this.data || option : '');
  }

  requestOTP() {
    this.phoneNumber = this.phoneNumber ? this.phoneNumber.trim() : this.phoneNumber;

    if (!this.phoneNumber) {
      return this.utilsService.showError('Please input Mobile No.');
    }

    if (!CONSTANTS.PHONE_NUMBER.test(this.phoneNumber)) {
      return this.utilsService.showError('Invalid Mobile No.');
    }

    const self = this;
    this.getGreCaptcha()
      .then(() => {
        grecaptcha.ready(function () {
          grecaptcha.execute({ action: 'login_page' }).then(async function (reCaptchaToken: any) {
            self.isLoading = true;
            try {
              await self.userService.requestOTP(self.phoneNumber, reCaptchaToken).toPromise();

              self.isRequestOTP = true;
              self.isLoading = false;
            } catch (error: any) {
              self.isRequestOTP = false;

              if (error.message === 'Maximum_Request_Limit') {
                self.utilsService.showError('You have exceeded the limit of verification attempts. Please try again in 1 hour.')
                self.isLoading = false;
                return self.hide(null);
              }

              self.isLoading = false;
              self.utilsService.showError(error.message);
            }
          })
        })
      })
  }

  async verifyOTP() {
    try {
      const otpNumber = `${this.num1}${this.num2}${this.num3}${this.num4}${this.num5}${this.num6}`;

      if (!this.phoneNumber || !otpNumber) {
        return;
      }

      this.isLoading = true;
      await this.userService.verifyOTP(this.phoneNumber, otpNumber).toPromise();
      this.isLoading = false;
      this.utilsService.showSuccess('Login Success!');
      if (this.redirectUrl) {
        this.router.navigateByUrl(this.redirectUrl)
      }
      this.hide({});
    } catch (error: any) {
      this.utilsService.showError(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  validate(event: any) {
    this.isEnable =
      this.isValidNumber(this.num1) &&
      this.isValidNumber(this.num2) &&
      this.isValidNumber(this.num3) &&
      this.isValidNumber(this.num4) &&
      this.isValidNumber(this.num5) &&
      this.isValidNumber(this.num6);

    if (Number(event.keyCode) >= 48 && Number(event.keyCode) <= 57) {
      this.focusNextInput(event);
      return;
    }

    if (event.keyCode == '8') {
      this.focusPrevInput(event);
    }
  }

  resendOTP() {
    this.isRequestOTP = false;
    this.num1 = undefined;
    this.num2 = undefined;
    this.num3 = undefined;
    this.num4 = undefined;
    this.num5 = undefined;
    this.num6 = undefined;
  }

  // private
  private isValidNumber(num: any) {
    if ((num != null || num != undefined) && num <= 9 && num >= 0) {
      return true;
    }
    return false;
  }

  private focusNextInput(event: any) {
    const element = event.srcElement.nextElementSibling; // get the sibling element
    if (element == null) {
      return;
    }

    element.focus();
  }

  focusPrevInput(event: any) {
    const element = event.srcElement.previousElementSibling; // get the sibling element
    if (element == null) {
      return;
    }

    element.focus();
  }
}
