import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../common/app-routes';
import { CONSTANTS } from '../../common/constants';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @Input() menus: any = [];
  @Output() clickLogin = new EventEmitter<any>();
  @Output() clickLogout = new EventEmitter<any>();

  userLogin: any;
  checked = false
  STICK_MENU = CONSTANTS.STICK_MENU;
  ROUTES = APP_ROUTES
  MYBOOKING = {
    name: 'My Booking',
    href: APP_ROUTES.HISTORY
  }

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    public usersService: UsersService
  ) {
    this.usersService.userLogin.subscribe(user => {
      this.userLogin = user;

      if (!this.userLogin) {
        this.menus = _.filter(this.menus, item => item.name !== 'My Booking');
        return;
      }
      
      const existItems = _.find(this.menus, item => item.name == 'My Booking');

      if (existItems) {
        return;
      }

      this.menus.push({
        name: 'My Booking',
        href: APP_ROUTES.HISTORY
      })
    });
  }

  ngOnInit(): void {
  }

  onClickFreeOnsultation() {
    this.router.navigate([`/${APP_ROUTES.CONSULTATION}`]);
  }

  onClickLogin() {
    this.clickLogin.emit();
  }

  onClickLogout() {
    this.clickLogout.emit();
  }

  clickStickMenu(item: any) {
    try {
      if (item && item.region) {
        return this.router.navigate([item.href], {
          fragment: item.region
        })
      }
  
      if (item && item.blank) {
        let newWin = window.open(item.href);

        if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
          return alert('Popup Blocker is enable. Please allow popups for this website!');
        }

        return;
      }
      
      return this.router.navigate([item.href]);
    } catch (error: any) {
      return this.utilsService.showError(error.message);
    }
  }

  onClickMyBooking() {
    this.router.navigate([`/${APP_ROUTES.HISTORY}`]);
  }

  onClickBookApp() {
    this.router.navigate([`${this.ROUTES.GARAGE}`]);
  }

  isShowBookAppBtn() {
    const url = this.router.url;
    return url == '/' || url == '/home';
  }
}
