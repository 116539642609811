import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { APP_ROUTES } from '../../common/app-routes';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private titleService: Title) { }

  updateMetaByRouter(router: string) {
    let data: any = {}
    switch (router) {
      case APP_ROUTES.HOME:
        data.title = "Car Service Centre | Best Car Repair Shop"
        data.description = "GoCar Garage is a one-stop Car Service Centre that provides quality car services to cater your needs. Book an appointment with the Best Car Repair Shop!"
        break;
      case APP_ROUTES.GARAGE:
        data.title = "Garage Near You"
        data.description = "Looking for quality car tinting service? Our Car Tint Specialist Shop offers the best service with quality assurance at affordable prices. Book Now."
        break;
      default:
        data.title = "Car Service Centre | Best Car Repair Shop"
        data.description = "GoCar Garage is a one-stop Car Service Centre that provides quality car services to cater your needs. Book an appointment with the Best Car Repair Shop!"
        break;
    }
    this.updateMeta(data)
  }

  updateMeta(data: any) {
    if (data.title) {
      this.titleService.setTitle(data.title)
    }
    if (data.description) {
      this.meta.updateTag({ name: 'description', content: data.description })
    }
    if (data.keywork) {
      this.meta.updateTag({ name: 'keywords', content: data.keywork })
    }
    if (data.title) {
      this.meta.updateTag({ property: 'og:title', content: data.title })
    }
    if (data.url) {
      this.meta.updateTag({ property: 'og:url', content: `${data.url}` })
    }
    if (data.description) {
      this.meta.updateTag({ property: 'og:description', content: data.description })
    }
    if (data.keywork) {
      this.meta.updateTag({ property: 'og:keywords', content: data.keywork })
    }
    if (data.imageUrl) {
      this.meta.updateTag({ property: 'og:image', content: data.imageUrl })
    }
  }

  updateMetaByCategory(categoryKey: string) {
    if (!categoryKey) return;
    let data: any = {};
    switch (categoryKey) {
      case 'periodic-service':
        data.title = 'Car Maintenance Workshop'
        data.description = `Schedule your car service and maintenance with us at our Car Maintenance Workshop to maintain the safe and smooth operation of your car. Call us now!`
        break;
      case 'tyre-service':
        data.title = 'Car Tyre Dealers'
        data.description = `GoCar Garage is one of the top Car Tyre Dealers that provides quality tyre services for your car. Book now to enjoy professional services at our workshop!`
        break;
      case 'air-cond-repair-service':
        data.title = 'Car Air Con Service'
        data.description = `Get your Car Air Con Service done with our skillful technicians! Book an appointment now with our car maintenance workshop for high-quality services.`
        break;
      case 'brake-repair-maintenance':
        data.title = 'Brake Checks Service'
        data.description = `GoCar Garage's Auto Repair Workshop provides efficient door-to-door pickup and drop-off services! Schedule an appointment for Brake Checks Service today!`
        break;
      case 'battery-inspection-replacement':
        data.title = 'Car Battery Service'
        data.description = `Time to change your car battery? Our Car Workshop Centre offers the best Car Battery Service at affordable prices. Book an appointment now!`
        break;
      case 'paint-and-dent':
        data.title = 'Car Painting Service'
        data.description = `GoCar Garage is a one-stop car service centre that provides a wide range of car services with quality assurance. Browse Car Painting Service centres today!`
        break;
      case 'car-accerssories':
        data.title = 'Car Accessories Installation'
        data.description = `Get your Car Accessories Installation services done with our professional technicians. We are a one-stop car service center, catering to all your needs.`
        break;
      case 'car-wash':
        data.title = 'Car Detailing Specialist'
        data.description = `It's essential for a car to have more than just a car wash. Contact a Car Detailing Specialist now to clean and restore your car to looking as good as new!`
        break;
      default:
        data.title = "Car Tint Specialist Shop"
        data.description = "Looking for quality car tinting service? Our Car Tint Specialist Shop offers the best service with quality assurance at affordable prices. Book Now."
        break;
    }
    this.updateMeta(data);
  }
}
