import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'

@Injectable()
export class GoogleServiceProvider extends BaseService {
  constructor(
    public override http: HttpClient
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getSuggestLocationsByText(text: string) {
    return this.get(`google-services/suggest-location/${text}`);
  }

  getPlaceById(placeId: string) {
    return this.get(`google-services/place-by-id/${placeId}`);
  }

  getAddressByLocation(lat: any, lng: any) {
    return this.get(`google-services/address-by-location/${lat}/${lng}`);
  }
}
