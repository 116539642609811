<app-main-layout>
  <div class="bg-grey pt-5 pb-5 main-content">
    <!-- Successful -->
    <figure *ngIf="isSuccess">
      <img src="../assets/images/img-success-pay.png" alt="Payment Success" width="100%" />
      <figcaption>Payment Successful</figcaption>
      <a class="mt-3 clickable" (click)="backToMyBooking()">Back to My Bookings</a>
    </figure>

    <!-- Failed -->
    <figure *ngIf="!isSuccess">
      <img src="../assets/images/img-failed-pay.png" alt="Payment Unsuccessful" width="100%" />
      <figcaption>Payment Unsuccessful</figcaption>
      <a class="mt-3 clickable" (click)="tryAgain()">Try again later</a>
    </figure>
  </div>
</app-main-layout>
<app-modal-rating [modalId]="MODAl_RATING"></app-modal-rating>