import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
import { BookingsService } from 'src/app/shared/services/bookings/bookings.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
declare var $: any
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  currentRating = 0
  comment = ''
  isLoading = false
  bookingId = ''
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private bookingService: BookingsService) {
    this.route.params.subscribe(ps => {
      this.bookingId = ps && ps['bookingId']
    })
  }

  ngOnInit(): void {
  }

  highlightPreviousStars(stars: number) {
    // Highlight the current and previous stars
    const currentStar = document.querySelector(`.star:nth-child(${stars})`);
    if (currentStar) {
      currentStar.classList.add('active');
      let previousStars = currentStar.previousElementSibling;
      while (previousStars) {
        previousStars.classList.add('active');
        previousStars = previousStars.previousElementSibling;
      }
    }
  }

  resetStars() {
    // Remove the active class from all stars except the ones that are part of the current rating
    const allStars = document.querySelectorAll('.star');
    allStars.forEach((star, index) => {
      if (index >= this.currentRating) {
        star.classList.remove('active');
      }
    });
  }

  setRating(stars: number) {
    // Set the rating and update the stars
    this.currentRating = stars;
    const allStars = document.querySelectorAll('.star');
    allStars.forEach((star, index) => {
      if (index < this.currentRating) {
        star.classList.add('active');
      } else {
        star.classList.remove('active');
      }
    });
  }

  async submit() {
    if (this.currentRating || this.comment) {
      try {
        const body = {
          star: this.currentRating,
          comment: this.comment,
          bookingId: this.bookingId
        }
        this.isLoading = true
        await this.bookingService.submitRating(body).toPromise()
        this.back()
      } catch (error: any) {
        this.utils.showError(error && error.message)
      } finally {
        this.isLoading = false
      }
    }
  }

  back() {
    this.router.navigate([APP_ROUTES.HISTORY])
  }

}
