<app-main-layout>
    <div class="container fs-18">
        <div class="row justify-content-center py-4">
            <div class="col-12 col-md-6 text-center">
                <h2>
                    Thank you for using GoCar Garage!
                </h2>
                <p>
                    We would love to hear your thoughts & feedback on how we can improve your experience.
                </p>
            </div>
        </div>
    </div>

    <div class="bg-gray py-4 fs-18">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">
                    <div class="white-block py-4 px-3 text-center">
                        <span>Please rate your overall experience</span>
                        <div class="text-center mt-3">
                            <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(1)"
                                (mouseout)="resetStars()" (click)="setRating(1)"></i>
                            <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(2)"
                                (mouseout)="resetStars()" (click)="setRating(2)"></i>
                            <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(3)"
                                (mouseout)="resetStars()" (click)="setRating(3)"></i>
                            <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(4)"
                                (mouseout)="resetStars()" (click)="setRating(4)"></i>
                            <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(5)"
                                (mouseout)="resetStars()" (click)="setRating(5)"></i>
                        </div>
                    </div>

                    <div class="white-block py-4 px-3 mt-4">
                        <span>Additional Feedback</span>
                        <div class="mt-2">
                            <textarea class="form-control" rows="4" placeholder="Add your Comments here" [(ngModel)]="comment"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-0 col-md-8"></div>
                <div class="col-12 col-md-6 mt-5 text-center">
                    <button class="btn btn-submit col-12" (click)="submit()">Submit</button>
                    <span class="btn-skip" (click)="back()">Skip</span>
                </div>
            </div>
        </div>
    </div>
</app-main-layout>
<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>