<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="content" >
                <span class="clickable btn-close">
                    <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
                </span>
                <div class="row header-section mt-3">
                    <!-- mobile only -->
                    <div class="col-lg-6 col-md-12 bookingId-mobile mb-3">
                        <strong style="background-color: #DAF5E0; padding: 6px 10px;">
                            Booking ID - {{data && data.bookingId}}
                        </strong>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                {{ data && data.car?.make }} {{ data && data.car?.model }} -
                                {{ data && data.car?.plate }}
                            </div>
                            <div class="col-lg-12 col-md-6 ">
                                {{data && data.timeSlot?.slot | dateTimeFormat: 'ddd, DD MMMM, HH:mm'}}
                            </div>
                        </div>
                    </div>
                    <!-- desktop only -->
                    <div class="col-lg-6 col-md-12 text-right bookingId">
                        <strong style="background-color: #DAF5E0; padding: 6px 10px;">
                            Booking ID - {{data && data.bookingId}}
                        </strong>
                    </div>
                </div>
                <div class="row content-section mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-7 col-md-6">
                                <strong>
                                    GoCar Garage Cost
                                </strong>
                            </div>
                            <div class="col-5 col-md-6 text-right">
                                <strong>RM{{getGarageCost() | number:'1.2-2'}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a *ngIf="data && data.invoices && data.invoices.length"
                            (click)="openPdf(data.invoices[0])">
                            View Invoice Details
                        </a>
                    </div>
                    <div class="col-12 get-promo-code mt-1" *ngIf="data && data.status == INVOICE_GENERATED">
                        <div class="row" style="align-items: center;">
                            <div class="col-12 col-md-3 mt-2">
                                <strong style="text-align: left;">Got a Promo Code?</strong>
                            </div>
                            <div class="col-12 col-md-6 mt-2">
                                <input type="text" placeholder="Enter Promo Code" class="form-control" [(ngModel)]="promoCode">
                            </div>
                            <div class="col-12 col-md-3 mt-2 apply-code">
                                <strong class="clickable" style="color: #3BA785;" (click)="getPrice()">Apply Code</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row cost-estimate">
                            <div class="col-6">
                                <strong>Total Payment</strong>
                            </div>
                            <div class="col-6 text-right">
                                <span class="cost">
                                    RM{{getTotalCost() | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row payment-method" *ngIf="data && data.status == INVOICE_GENERATED">
                    <div class="col-12 mb-2">
                        <strong>Choose Payment Method</strong>
                    </div>
                    <div class="col-12 mb-2">
                        <div class="payment mb-2">
                            <div class="row clickable" (click)="isShowCredit = !isShowCredit">
                                <div class="col-2 col-md-1">
                                    <i class="fas fa-credit-card" style="font-size: 20px; color: #3BA785;"></i>
                                </div>
                                <div class="col-8 col-md-10 p-0">
                                    <span style="font-weight: bold;">Credit/Debit Card</span>
                                </div>
                                <div class="col-2 col-md-1">
                                    <i [ngClass]="!isShowCredit ? 'fas fa-angle-up':'fas fa-angle-down'"
                                        style="color: gray; margin-right: 5px"></i>
                                </div>
                            </div>
                            <ul class="mt-3" *ngIf="isShowCredit">
                                <li *ngFor="let method of creditCards">
                                    <input type="radio" name="method-{{method.id}}" id="method-{{method.id}}" [value]="method.id" [(ngModel)]="paymentMethod"
                                        style="margin-right: 15px; accent-color: #3BA77B;">
                                    <label for="method-{{method.id}}">{{method.name}}</label>
                                </li>
                            </ul>
                        </div>
                        <div class="payment mb-2">
                            <div class="row clickable" (click)="isShowOnlineBanking = !isShowOnlineBanking">
                                <div class="col-2 col-md-1">
                                    <i class="fas fa-university" style="font-size: 20px; color: #3BA785;"></i>
                                </div>
                                <div class="col-8 col-md-10 p-0">
                                    <span style="font-weight: bold;">Online Banking</span>
                                </div>
                                <div class="col-2 col-md-1">
                                    <i [ngClass]="!isShowOnlineBanking ? 'fas fa-angle-up':'fas fa-angle-down'"
                                        style="color: gray; margin-right: 5px"></i>
                                </div>
                            </div>
                            <ul *ngIf="isShowOnlineBanking">
                                <li class="mt-3" *ngFor="let method of onlineBankings">
                                    <input type="radio" name="method-{{method.paymentId}}" id="method-{{method.paymentId}}" [value]="method.paymentId" [(ngModel)]="paymentMethod"
                                        style="margin-right: 15px; accent-color: #3BA77B;">
                                    <label for="method-{{method.paymentId}}">{{method.name}}</label>
                                </li>
                            </ul>
                        </div>
                        <div class="payment">
                            <div class="row clickable" (click)="isShowBNPL = !isShowBNPL">
                                <div class="col-2 col-md-1">
                                    <i class="fas fa-wallet" style="font-size: 20px; color: #3BA785;"></i>
                                </div>
                                <div class="col-8 col-md-10 p-0">
                                    <span style="font-weight: bold;">Buy Now Pay Later</span>
                                </div>
                                <div class="col-2 col-md-1">
                                    <i [ngClass]="!isShowBNPL ? 'fas fa-angle-up':'fas fa-angle-down'"
                                        style="color: gray; margin-right: 5px"></i>
                                </div>
                            </div>
                            <ul *ngIf="isShowBNPL">
                                <li class="mt-3" *ngFor="let method of bnplMethods">
                                    <input type="radio" name="method-{{method.id}}" id="method-{{method.id}}" [value]="method.id" [(ngModel)]="paymentMethod"
                                        style="margin-right: 15px; accent-color: #3BA77B;">
                                    <label for="method-{{method.id}}">{{method.name}}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 mb-2 mt-2" style="display: flex; align-items: flex-start;">
                        <input type="radio" name="isAgree1" id="isAgree1" [value]="true" [(ngModel)]="isAgree1"
                            style="margin-right: 15px; margin-top: 5px; accent-color: #3BA77B;">
                        <span>I hereby acknowldge and confirm receipt of the 
                            <span (click)="openTermAndConditions()" class="text-link clickable">
                                GoGarage PDPA
                            </span>
                             and give consent to process my personal data in accordance with said notice.</span>
                    </div>
                    <div class="col-12 mb-3" style="display: flex; align-items: flex-start;">
                        <input type="radio" name="isAgree2" id="isAgree2" [value]="true" [(ngModel)]="isAgree2"
                            style="margin-right: 15px; margin-top: 5px; accent-color: #3BA77B;">
                        <span>I hereby accept the 
                            <span (click)="openTermAndConditions()" class="text-link clickable">
                                GoGarage Terms & Conditions
                            </span>
                        </span>
                    </div>
                    <div class="col-12">
                        <button (click)="paymentNow()" class="btn">
                            Payment Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>
</div>

<app-modal-confirm-common [modalId]="MODAL_CONFIRM_PAYMENT_NOW" [confirmContent]="'Are you sure want to approve?'"
    (choose)="confirmPaymentNow($event)">
</app-modal-confirm-common>