import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as config } from "../../../../environments/environment";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: 'root'
})
export class VouchersService extends BaseService {
  prefixURL = 'vouchers'
  constructor(
    public override http: HttpClient) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  checkVoucher(data: any) {
    return this.post(`${this.prefixURL}/check-voucher-code`, data);
  }
}
