// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // gogarage_api: "http://localhost:8031/gocar-garage-api/website",
  gogarage_api: 'https://garage-api.gocardev.com/gocar-garage-api/website',
  authorization_key: 'gogarage',
  authorization_value: '8HFsEl6TpTtDwy1P3vCWHLgqpXI6Z1wP',
  siteKeyCaptcha: '6LfIwawUAAAAAFKkkeXdwYjRAOZFimjnoaFzHA9a',

  GOCAR_HELPER_API_URL: 'https://dev-gocar-helper-api-3i65asokvq-as.a.run.app',
  GOCAR_HELPER_SECRET_KEY: "gocar_helper_key",
  GOCAR_HELPER_SECRET_VALUE: "2XsE3ydpFukcObeEp6QV8IKJZInhDdmW",
  apiEncryptKey: "7ZuKqj8oHbY3L1V",
  gocar_support: {
    isUseGoCarChatWebsite: true,
    goCarChatWebsiteUrl: "https://dev-gocar-chat-website-3i65asokvq-as.a.run.app",
    goCarChatJSUrl: "https://dev-gocar-chat-dashboard-3i65asokvq-as.a.run.app/assets/chat.min.js",
    isShowFs: true,
    token: '963eb9d1-b910-46e8-a390-8caf356787eb',
    host: 'https://wchat.freshchat.com',
    tags: ['home', 'reservation', 'gocar_sub'],
    config: {
      headerProperty: {
        // If you have multiple sites you can use the appName and appLogo to overwrite the values.
        appName: 'GoCar Team',
        appLogo: 'https://d1qb2nb5cznatu.cloudfront.net/startups/i/2473-2c38490d8e4c91660d86ff54ba5391ea-medium_jpg.jpg?buster=1518574527',
        backgroundColor: '#37BC9B',
        foregroundColor: '#333333',
        backgroundImage: 'https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png',
        hideChatButton: true
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
