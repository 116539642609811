import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modal-confirm-common',
  templateUrl: './modal-confirm-common.component.html',
  styleUrls: ['./modal-confirm-common.component.scss']
})
export class ModalConfirmCommonComponent implements OnInit {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  click(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }
}
