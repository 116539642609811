<app-main-layout [page]="ROUTER.CONSULTATION">
    <div class="bg-light pb-5">
        <section>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row"
                            style="display: flex; justify-content: center; align-items: center; margin: auto;">
                            <div class="bg-grey pt-5 pb-5 main-content">
                                <figure>
                                    <img src="../assets/images/img-thank-you.png" alt="Payment Success" width="100%" />
                                    <figcaption>THANK YOU</figcaption>
                                    <p>Your consultation is successfully submitted. <br />
                                        <strong>We will get back to you in 5 days time</strong>
                                    </p>
                                    <a class="mt-3 clickable" (click)="backToHome()">Back to Homepage</a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</app-main-layout>