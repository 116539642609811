import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-modal-view-garage-detail',
  templateUrl: './modal-view-garage-detail.component.html',
  styleUrls: ['./modal-view-garage-detail.component.scss']
})
export class ModalViewGarageDetailComponent implements OnInit {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();

  isLoading = false;

  constructor() { }

  ngOnInit() {

  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }
}
