<footer class="pt-5 pb-5">
  <div class="container p30 free-space-bottom">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-3">
            <img class="mb-3" src="../assets/images/img-logo-white.png" alt="GoCar Logo" />
            <p>Lot 1A, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
          </div>
          <div class="col-md-2 sitemap">
            <ul style="padding-left: 0;">
              <li class="clickable"><a href="{{APP_ROUTES.HOME}}">Home</a></li>
              <li class="clickable"><a href="{{APP_ROUTES.HOME}}#howtobook">How It Work</a></li>
              <li class="clickable"><a href="{{APP_ROUTES.GARAGE}}">Garage Near You</a></li>
              <li class="clickable"><a href="{{APP_ROUTES.HOME}}#whygogarage">Why Choose Us</a></li>
              <li class="clickable"><a href="{{APP_ROUTES.CONSULTATION}}">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-lg-7 download-app">
            <strong class="mt-2">Download Mobile App</strong>
            <a href="{{DOWNLOAD.IOS}}" target="_blank" class="clickable">
              <img class="mb-3 mr-1" src="../assets/images/apple.png" alt="Apple Store" />
            </a>
            <a href="{{DOWNLOAD.ANDROID}}" target="_blank" class="clickable">
              <img class="mb-3" src="../assets/images/android.png" alt="Android Play Store" />
            </a>
            <p class="mt-3 mb-0">© GoCar Mobility Sdn Bhd 201501016594 (1141928-U) |
              <a href="https://gocarhelp.zendesk.com/hc/en-us/articles/4672618125337-GoCar-Terms-Conditions"
                target="_blank" class="clickable">Terms & Conditions</a>
            </p>
            <p>
              <a href="https://hi.gocar.my/wp-content/uploads/2020/05/WTCH-GROUP_-ANTI-BRIBERY-ANTI-CORRUPTION-POLICY.pdf"
                target="_blank" class="clickable">Anti-Bribery & Anti-Corruption Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>