import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseService {
  prefixURL = 'categories';
  constructor(public override http: HttpClient) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getAll(garageId = ''): Observable<any> {
    return this.get(`${this.prefixURL}/all?garageId=${garageId}`);
  }

  getListCategories(garageId: string) {
    return this.get(`${this.prefixURL}/all?garageId=${garageId}`)
  }
}
