import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CONSTANTS } from "../common/constants";
import { UsersService } from "../services/users/users.service";
import { APP_ROUTES } from "../common/app-routes";

@Injectable()
export class AuthLogin implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const loginInfo = this.usersService.getLoginLocal();

      const isExpiriedToken = loginInfo ? (new Date().getTime() - loginInfo.loginAt) >= CONSTANTS.ONE_DAY_IN_MILLISECONDS : true;
      
      // token is valid
      if (!isExpiriedToken) {
        return true;
      }

      // remove current token info;
      this.usersService.logout();

      this.router.navigate([''], {
        queryParams: {
          redirectUrl: state.url
        }
      });
      return false;
    } catch (error) {
      this.router.navigate(['/']);
      return false;
    }
  }
}
