<div class="modal" [id]="modalId" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header dialog-header">
        <h3 class="modal-title m-auto">Choose Date</h3>
        <button type="button" class="close m-0 p-0" style="color: #fff; opacity: 1;" (click)="cancel()">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 p-0 text-center">
            <p-calendar [(ngModel)]="dateSelected" 
              [style]="{'width':'95%'}" placeholder="Select Date" dateFormat="yy/mm/dd"
              [readonlyInput]="true"
              [showIcon]="true" [showTime]="false" [inline]="true"
              [minDate]="minDate"
              >
            </p-calendar>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
          (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-confirm"
          (click)="confirm()">Confirm</button>
      </div>
    </div>
  </div>
</div>