import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common'
import { CONSTANTS } from '../../common/constants';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  DATE_FORMAT = 'YYYY-MM-DD';
  constructor(
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID)
    private platformId: Object,
  ) { }

  showSuccess(message: string = 'Success!') {
    this.toastrService.clear();
    this.toastrService.success(message);
  }

  showError(message: string = 'Error!') {
    this.toastrService.clear();
    this.toastrService.error(message);
  }

  showWarning(message: string = 'Warning!') {
    this.toastrService.clear();
    this.toastrService.warning(message);
  }

  getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!isPlatformBrowser(this.platformId)) {
        return resolve(null);
      }

      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          } else {
            // this.showWarning('GPS is disabled. Please turn on your GPS function.')
            resolve({
              lat: CONSTANTS.GOCAR_HQ_LOCATION.lat,
              lng: CONSTANTS.GOCAR_HQ_LOCATION.lng
            });
            // reject(new Error('GPS is disabled. Please turn on your GPS function.'));
          }
        }, (error) => {
          // this.showWarning('GPS is disabled. Please turn on your GPS function.')
          resolve({
            lat: CONSTANTS.GOCAR_HQ_LOCATION.lat,
            lng: CONSTANTS.GOCAR_HQ_LOCATION.lng
          });
          // reject(new Error('GPS is disabled. Please turn on your GPS function.'));
        });
      } else {
        reject(new Error('Geolocation is not supported by this browser!'));
      }
    });
  }

  createUniqueString() {
    let length = 6
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var randomString = '';
    for (var i = 0; i < length; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  getLocalStorage(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let data = localStorage.getItem(key);

      return data;
    }

    return null;
  }

  setLocalStorage(key: string, data: any) {
    if (!key) {
      return;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!data) {
      return localStorage.setItem(key, '');
    }

    localStorage.setItem(key, data);
  }

  removeLocalStorage(key: string) {
    if (!key) {
      return;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    localStorage.removeItem(key);
  }

  fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  convertDate(date: any, format = 'YYYY-MM-DD') {
    if (date) {
      return moment.parseZone(date).format(format)
    }
    return ''
  }

  compareTowDates(date1: any, date2: any) {
    const d1 = moment(date1)
    const d2 = moment(date2)
    return d1.diff(d2, 'days')
  }

  setDataFromGocarApp(params: any) {
    if (params && params.isGocarApp) {
      this.setLocalStorage('isGocarApp', 'true');
    }

    // if (params && params.userId) {
    //   const userBasicInfo = {
    //     userId: params.userId
    //   }
    //   this.setLocalStorage('user_info', JSON.stringify(userBasicInfo));
    // }

    // if (params && params.token) {
    //   this.setLocalStorage('user_token', params.token);
    // }
  }

  get isGocarApp() {
    return this.getLocalStorage('isGocarApp') || false
  }

  showTimeSlotSession(session: string) {
    return this.showConstantName(CONSTANTS.TIME_SLOT_SESSION, session);
  }

  showConstantName(constants: any, value: any) {
    if (!constants || !value) {
      return '';
    }
    const arrays = Object.keys(constants).map(key => constants[key]);
    const item = arrays.find(item => item.value === value);

    return item && item.name || value || '';
  }
}
