import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingsService } from '../../services/bookings/bookings.service';
import { GocarDialogService } from '../../services/gocar-dialog/gocar-dialog.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ModalIframeComponent } from '../modal-iframe/modal-iframe.component';

declare var $: any;

@Component({
  selector: 'app-modal-quotation',
  templateUrl: './modal-quotation.component.html',
  styleUrls: ['./modal-quotation.component.scss']
})
export class ModalQuotationComponent implements OnInit {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();
  MODAL_CONFIRM_APPOVAL_QUOTATION = 'modal-confirm-approve-quotation';
  MODAL_CONFIRM_REJECT_QUOTATION = 'modal-confirm-reject-quotation';

  isLoading = false;
  attacts: any = [
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' }
  ];

  constructor(
    private utilsService: UtilsService,
    private bookingService: BookingsService,
    private gocarDialogService: GocarDialogService,
  ) { }

  ngOnInit() {

  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }

  approval() {
    this.showModal(this.MODAL_CONFIRM_APPOVAL_QUOTATION)
  }

  async confirmApproval(option: any) {
    if (option === 'ok') {
      if (this.data && this.data.quotation) {
        try {
          this.isLoading = true;
          await this.bookingService.approveOrDisapproveQuotation(this.data.id, this.data.quotation.id, true).toPromise();
          this.isLoading = false;
          this.choose.emit(this.data);
          $(`#${this.modalId}`).modal("hide");
        } catch (error: any) {
          console.log(error);
          this.isLoading = false;
          this.utilsService.showError(error && error.message);
        }
      }
    }
  }

  reject() {
    this.showModal(this.MODAL_CONFIRM_REJECT_QUOTATION)
  }

  async confirmReject(option: any) {
    if (option === 'ok') {
      if (this.data && this.data.quotation) {
        try {
          this.isLoading = true;
          await this.bookingService.approveOrDisapproveQuotation(this.data.id, this.data.quotation.id, false).toPromise();
          this.isLoading = false;
          this.choose.emit(this.data);
          $(`#${this.modalId}`).modal("hide");
        } catch (error: any) {
          console.log(error);
          this.isLoading = false;
          this.utilsService.showError(error && error.message);
        }
      }
    }
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  openPdf(quotation: any) {
    if (this.utilsService.isGocarApp) {
      this.gocarDialogService.show(ModalIframeComponent, {
        data: quotation,
        showHeader: false,
        width: '90%',
        contentStyle: {
          "height": "70vh", "overflow": "auto", "border-radius": '10px', "padding": '15px'
        },
      }).then(result => {
        console.log(result)
      })
    } else {
      const fileUrl = quotation.pdfUrl
      window.open(fileUrl, "_blank")
    }
  }
}
