<app-main-layout [page]="ROUTER.CONSULTATION">
  <div class="bg-light pb-5">
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="userInfoForm">
              <div class="row" style="display: flex; justify-content: center; align-items: center; margin: auto;">
                <ng-container *ngIf="!isSubmittedSuccess">
                  <div class="col-lg-8 col-md-12 mt-3">
                    <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
                      <ol class="breadcrumb mb-0 pl-0">
                        <li class="breadcrumb-item clickable"><a routerLink="/{{ROUTER.HOME}}">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Get A Quote</li>
                      </ol>
                    </nav>
                  </div>

                  <div class="col-lg-8 col-md-12">
                    <div class="form-wrapper mt-3 pt-0">
                      <h1 class="form-header-title xxl-text-bold">Get A Quote</h1>
                      <div class="form-container">
                        <label class="label mt-2">Name</label>
                        <input class="form-control normal-text" type="text" placeholder="Enter Name"
                          formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                        <div *ngIf="submitted && form['name'].errors" class="is-invalid invalid-feedback">
                          <div *ngIf="form['name'].errors['required']">
                            Name is required
                          </div>
                        </div>

                        <label class="label mt-3">Car Brand</label>
                        <select class="form-control normal-text selectpicker carmake" (change)="selectCarMake($event)"
                          formControlName="make" [ngClass]="{ 'is-invalid': submitted && form['make'].errors }"
                          data-actions-box="true" data-size="10" data-style='btn-dropdown'>
                          <option data-hidden="true" class="" value="null" disable>
                            Choose Car Brand
                          </option>
                          <option *ngFor="let make of carsMakeDefault" class="normal-text" value="{{ make.id }}">
                            {{ make.name }}
                          </option>
                          <option class="normal-text" value="other">
                            Others
                          </option>
                        </select>
                        <div *ngIf="submitted && form['make'].errors" class="is-invalid invalid-feedback d-block">
                          <div *ngIf="form['make'].errors['required']">
                            Car Brand is required
                          </div>
                        </div>
                        <input class="form-control normal-text mt-3" type="text" placeholder="Enter Brand"
                          formControlName="makeOther" [ngClass]="{
                          'is-invalid': submitted && form['makeOther'].errors
                        }" *ngIf="userInfoForm?.value?.make == 'other'" />

                        <label class="label mt-3">Car Model</label>
                        <div class="mb-3" *ngIf="userInfoForm?.value?.make != 'other'">
                          <select class="form-control normal-text selectpicker carmodel" formControlName="model"
                            [ngClass]="{
                            'is-invalid': submitted && form['model'].errors && userInfoForm?.value?.make != 'other'
                          }" data-style='btn-dropdown' (change)="selectCarModel($event)">
                            <option data-hidden="true" class="" value="null" disable>
                              Choose Car Model
                            </option>
                            <option *ngFor="let model of carsModelDefault" class="normal-text" value="{{ model.id }}">
                              {{ model.name }}
                            </option>
                            <option class="normal-text" value="other">
                              Others
                            </option>
                          </select>
                        </div>
                        <div *ngIf="submitted && form['model'].errors && userInfoForm?.value?.make != 'other'"
                          class="is-invalid invalid-feedback d-block">
                          <div *ngIf="form['model'].errors['required']">
                            Car Model is required
                          </div>
                        </div>
                        <input class="form-control normal-text" type="text" placeholder="Enter model"
                          formControlName="modelOther" [ngClass]="{
                          'is-invalid': submitted && form['modelOther'].errors
                        }" *ngIf="userInfoForm?.value?.make == 'other' || userInfoForm?.value?.model == 'other'" />

                        <label class="label mt-3">Email</label>
                        <input class="form-control normal-text" type="text" placeholder="Enter Email"
                          formControlName="email" [ngClass]="{
                          'is-invalid': submitted && form['email'].errors
                        }" />
                        <div *ngIf="submitted && form['email'].errors" class="is-invalid invalid-feedback">
                          <div *ngIf="form['email'].errors['required']">
                            Email is required
                          </div>
                        </div>

                        <label class="label mt-3">Contact No.</label>
                        <input class="form-control normal-text" type="text" numbersOnly placeholder="Enter Contact No."
                          [ngClass]="{
                          'is-invalid': submitted && form['phoneNumber'].errors
                        }" formControlName="phoneNumber" />
                        <div *ngIf="submitted && form['phoneNumber'].errors" class="is-invalid invalid-feedback"
                          [ngClass]="{
                          'is-invalid': submitted && form['phoneNumber'].errors
                        }">
                          <div *ngIf="form['phoneNumber'].errors['required']">
                            Contact No. is required
                          </div>
                        </div>

                        <label class="label mt-3">Upload Image</label>
                        <button class="btn btn-upload mt-1 normal-text" (click)="uploadImage()">Attach Images</button>
                        <input class="form-control-file" accept="image/*" type="file" id="quote-image"
                          formControlName="photo" (change)="attachImage($event)" multiple
                          style="visibility: hidden; position: absolute; width: 50px;">

                        <div class="wrapper-image mt-2">
                          <div *ngFor="let image of images; let i = index" class="image-item">
                            <div class="image-upload" style="position: relative;">
                              <i class="fa fa-times icon-delete clickable"
                                style="position: absolute; top: 5px; right: 5px" (click)="removeImage(i)"></i>
                              <img [src]="image" style="width: 100%; height: 100%;" alt="Uploaded Photo">
                            </div>
                          </div>
                        </div>

                        <label class="label mt-3">Describe Your Car’s Condition</label>
                        <textarea class="form-control normal-text" placeholder="Enter Describe Your Car’s Condition"
                          formControlName="description" [ngClass]="{
                          'is-invalid': submitted && form['description'].errors
                        }" [rows]="4"></textarea>
                        <div *ngIf="submitted && form['description'].errors" class="is-invalid invalid-feedback"
                          [ngClass]="{
                          'is-invalid': submitted && form['description'].errors
                        }">
                          <div *ngIf="form['description'].errors['required']">
                            Describe Your Car’s Condition is required
                          </div>
                        </div>

                        <div>
                          <div id="captcha_consultation" class="g-recaptcha" [attr.data-sitekey]="siteKey"></div>
                        </div>

                        <div class="form-wrapper mt-3">
                          <button class="form-control btn-confirm" (click)="submitConsultation()">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isSubmittedSuccess">
                  <div class="bg-grey pt-5 pb-5 main-content">
                    <figure>
                      <img src="../assets/images/img-thank-you.png" alt="Payment Success" width="100%" />
                      <figcaption>THANK YOU</figcaption>
                      <p>Your consultation is successfully submitted. <br />
                        <strong>We will get back to you in 5 days time</strong>
                      </p>
                      <a class="mt-3 clickable" (click)="backToHome()">Back to Homepage</a>
                    </figure>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</app-main-layout>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>
<app-modal-confirm-common [modalId]="MODAL_CONFIRM_SUBMIT_CONLSUTATION"
  confirmContent="Are you sure want to submit the form?" (choose)="confirmSubmitConsultation($event)">
</app-modal-confirm-common>