import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ConsultationComponent } from './pages/consultation/consultation.component';
import { AuthLogin } from './shared/auth/auth.login';
import { APP_ROUTES } from './shared/common/app-routes';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PaymentResultComponent } from './pages/payment-result/payment-result.component';
import { SuccessComponent } from './pages/consultation/success/success.component';
import { LandingComponent } from './pages/landing/landing.component';
import { RatingComponent } from './pages/rating/rating.component';
import { CalendarAuthCallbackComponent } from './pages/calendar-auth-callback/calendar-auth-callback.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: []
  },
  {
    path: APP_ROUTES.CONSULTATION,
    component: ConsultationComponent,
  },
  {
    path: APP_ROUTES.CONSULTATION + '/submit-success',
    component: SuccessComponent,
  },
  {
    path: APP_ROUTES.HISTORY,
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule),
    canActivate: [AuthLogin]
  },
  {
    path: APP_ROUTES.GARAGE, loadChildren: () => import(`./pages/garages/garages.module`).then(m => m.GaragesModule),
    // canActivate: [AuthLogin]
  },
  {
    path: APP_ROUTES.PAYMENT_SUCCESS,
    component: PaymentResultComponent,
    data: {
      status: 0
    }
  },
  {
    path: APP_ROUTES.PAYMENT_FAILED,
    component: PaymentResultComponent,
    data: {
      status: 1
    }
  },
  {
    path: APP_ROUTES.RATING + '/:bookingId',
    component: RatingComponent,
    canActivate: [AuthLogin]
  },
  {
    path: APP_ROUTES.CALENDAR_AUTH_CALLBACK,
    component: CalendarAuthCallbackComponent
  },
  { path: APP_ROUTES.NOT_FOUND, component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
