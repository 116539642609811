import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseService {
  prefixURL = 'products';

  constructor(
    public override http: HttpClient
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getProducts(categoryId: string, garageId: string) {
    return this.get(`${this.prefixURL}?categoryId=${categoryId}&garageId=${garageId}`)
  }
}
