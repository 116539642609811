import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
declare var $: any;
@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {
  ROUTER = APP_ROUTES;
  isSuccess = false;
  MODAl_RATING = 'modal-rating'

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.isSuccess = !event.snapshot.data['status']
      }
    });
  }

  ngOnInit(): void {
    if (this.isSuccess) {
      setTimeout(() => {
        this.showModal(this.MODAl_RATING)
      }, 100);
    }
  }

  tryAgain() {
    this.router.navigate([`/${this.ROUTER.HISTORY}`]);
  }

  backToMyBooking() {
    this.router.navigate([`/${this.ROUTER.HISTORY}`]);
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }
}
