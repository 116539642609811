import { Injectable } from '@angular/core';
import { Upload } from '../../models/upload';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UploadFileService {
  uploads: any;
  apiUrl = environment.GOCAR_HELPER_API_URL;

  constructor() { }

  startUploadingImage(fileUpload: any, path: any) {
    return new Promise((res, rej) => {
      if (fileUpload) {
        this.uploadToGCP(fileUpload, path).then((result: any) => {
          if (result) {
            res({ url: result })
          } else {
            res('')
          }
        }).catch(err => {
          rej(false)
        });
      } else {
        rej(false)
      }
    })
  }

  /**
   * Call backend to upload file to GCP
   * @param fileUpload 
   * @param filePath path of folder, dont include filename bcoz it was generated by server
   * @returns a downloadable url
   */
  async uploadToGCP(fileUpload: Upload, path?: string) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', fileUpload.file);

      const url = `${this.apiUrl}/storages/upload?path=${path}`;
      var xhr = new XMLHttpRequest();
      xhr.open('post', url, true);
      xhr.setRequestHeader(environment.GOCAR_HELPER_SECRET_KEY, environment.GOCAR_HELPER_SECRET_VALUE);
      xhr.responseType = 'json';

      xhr.onreadystatechange = function (a) {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          try {
            resolve(xhr.response.data);
          } catch (error) {
            reject('Cannot upload');
          }
        }
      }

      xhr.onerror = function (e) {
        console.log('Error');
        console.log(e);
      };
      xhr.onload = function () {
        console.log(this.statusText);
      };

      xhr.send(formData);

    });
  }
}
