
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../../common/constants';
import { UtilsService } from '../../services/utils/utils.service';

declare var $: any;
@Component({
  selector: 'app-modal-select-date-time',
  templateUrl: './modal-select-date-time.component.html',
  styleUrls: ['./modal-select-date-time.component.scss']
})
export class ModalSelectDateTimeComponent implements OnInit {
  @Input() modalId = '';
  @Output() choose = new EventEmitter<any>();

  TIME_OPTIONS_DEFAULT = [
    { id: '09:00:00', name: '9:00AM' },
    { id: '10:00:00', name: '10:00AM' },
    { id: '11:00:00', name: '11:00AM' },
    { id: '12:00:00', name: '12:00PM' },
    { id: '13:00:00', name: '1:00PM' },
    { id: '14:00:00', name: '2:00PM' },
    { id: '15:00:00', name: '3:00PM' },
    { id: '16:00:00', name: '4:00PM' },
    { id: '17:00:00', name: '5:00PM' },
    { id: '18:00:00', name: '6:00PM' }
  ]
  TIME_OPTIONS: any = []
  minDate = new Date()
  dateSelected = new Date();
  timeSelected: any = {}
  DOWNLOAD = CONSTANTS.DOWNLOAD
  pCalendarStyle: any = {
    'width': '94%',
    'border': '1px solid #ced4da',
    'margin-top': '5px',
    'border-radius': '5px'
  }
  isShowCalendar = {
    time: false,
    date: false
  }
  @ViewChild('inputDate') inputDate: any;
  @ViewChild('calendaDate') calendaDate: any;
  // @ViewChild('inputTime') inputTime: any;
  // @ViewChild('calendaTime') calendaTime: any;
  constructor(
    private toastrService: ToastrService,
    private utils: UtilsService
  ) { }

  ngOnInit() { 
    this.onselectDate()
  }

  continue() {
    if (!this.dateSelected) {
      this.toastrService.error('Please select a date!')
      return
    }
    // if (!this.timeSelected.id) {
    //   this.toastrService.error('Please choose time!')
    //   return
    // }
    let data = {
      date: moment(this.dateSelected).format("YYYY-MM-DD"),
      // time: this.timeSelected.id
    };

    this.choose.emit(data);
    this.hide();
  }

  onselectDate() {
    // if (this.dateSelected && !this.utils.compareTowDates(this.minDate, this.dateSelected)) {
    //   const time = moment(this.dateSelected).format('HH:mm:ss')
    //   this.TIME_OPTIONS = this.TIME_OPTIONS_DEFAULT.filter(item => item.id >= time)
    // }
  }

  cancel() {
    this.choose.emit();
    this.hide()
  }

  hide() {
    $(`#${this.modalId}`).modal("hide");
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.inputDate.nativeElement.contains(event.target) && this.calendaDate && !this.calendaDate.nativeElement.contains(event.target)) {
      this.isShowCalendar.date = false;
    }

    // if (!this.inputTime.nativeElement.contains(event.target) && this.calendaTime && !this.calendaTime.nativeElement.contains(event.target)) {
    //   this.isShowCalendar.time = false;
    // }
  }
}
