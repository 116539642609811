import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment as config } from '../../../environments/environment'
import * as _ from 'lodash';
import { UtilsService } from './utils/utils.service';
import { CONSTANTS } from '../common/constants';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  OBJECT_ERROR = { "code": 400, "message": "No internet connection detected. Please check and try again." };
  UNEXPECTED_ERROR = { "code": 400, "message": "An error occurred, please try again later!" };
  apiURL = '';
  key = '';

  constructor(protected http: HttpClient, private utilsSerice?: UtilsService) {
    this.apiURL = config.gogarage_api;
    this.key = config.apiEncryptKey;
  }

  private createHttpOptions(params?: any, headersPairs?: any) {
    let myHeaders = {
      [config.authorization_key]: config.authorization_value,
      'Content-Type': 'application/json',
      observe: 'response'
    } as any;

    if (this.key && this.apiURL === config.gogarage_api) {
      myHeaders['x-encrypt'] = 'true';
    }

    let token = this.utilsSerice?.getLocalStorage('user_token');

    const userInfo = this.utilsSerice?.getLocalStorage('user_info');

    if (token) {
      myHeaders['user_token'] = token;
    }

    if (userInfo) {
      myHeaders['user-info'] = userInfo;
    }

    if (this.apiURL === config.gogarage_api) {
      myHeaders[config.authorization_key] = config.authorization_value;
    }

    if (headersPairs) {
      Object.keys(headersPairs).forEach(key => {
        myHeaders[key] = headersPairs[key];
        if (key === 'Content-Type' && !headersPairs[key]) {
          delete myHeaders[key];
        }
      });
    }

    let headers = new HttpHeaders(myHeaders);

    return { headers, params };
  }

  get(path: string, params?: any): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/${path}`, this.createHttpOptions(params))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        }))
  }

  post(path: string, body: any, headersPairs?: any): Observable<any> {
    const encryptedBody = this.encryptRequestBody(body);
    return this.http
      .post<any>(`${this.apiURL}/${path}`, encryptedBody, this.createHttpOptions(null, headersPairs)).pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        })
      );
  }

  patch(path: string, body: any): Observable<any> {
    const encryptedBody = this.encryptRequestBody(body);
    return this.http
      .patch<any>(`${this.apiURL}/${path}`, encryptedBody, this.createHttpOptions())
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          // Decrypt error response body using AES decryption
          return this.getError(err);
        }));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete<any>(`${this.apiURL}/${path}`)
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        }));
  }

  put(path: string, body: any, headersPairs?: any): Observable<any> {
    const encryptedBody = this.encryptRequestBody(body);
    return this.http
      .put<any>(`${this.apiURL}/${path}`, encryptedBody, this.createHttpOptions(null, headersPairs))
      .pipe(
        // Decrypt response body using AES decryption
        map((response: any) => {
          return this.decryptResponse(response);
        }),
        catchError((err) => {
          return this.getError(err);
        }));
  }

  // encrypt request body
  private encryptRequestBody(body: any) {
    if (!this.key || this.apiURL !== config.gogarage_api) {
      return body;
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), this.key).toString();
    const encryptedBody = { data: ciphertext };
    return encryptedBody;
  }

  private decryptResponse(response: any) {
    if (!this.key || this.apiURL !== config.gogarage_api || (typeof response?.data !== 'string')) {
      return response;
    }

    const encryptedResponse = response;
    const bytes = CryptoJS.AES.decrypt(encryptedResponse.data, this.key);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedResponse = JSON.parse(plaintext);
    return decryptedResponse;
  }

  getError(err: HttpErrorResponse) {
    // decrypt error message
    if (this.key && this.apiURL === config.gogarage_api && err.error && err.error.data) {
      const bytes = CryptoJS.AES.decrypt(err.error.data, this.key);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedResponse = JSON.parse(plaintext);
      const decryptError = { ...err };
      decryptError.error = decryptedResponse;
      err = new HttpErrorResponse({
        error: new Error(decryptError.error.message)
      })
    }

    // disable due garage still response 500 with meaning message(e.g. Maximum_Request_Limit)
    // if (err.status == 500) {
    //   err = new HttpErrorResponse({
    //     error: new Error(CONSTANTS.UNKNOW_ERROR)
    //   })
    // }
    return throwError(() => err.error || err);
  }
}
