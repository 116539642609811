<div class="modal" [id]="modalId" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="content">
        <span class="btn-close" (click)="hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <div class="row">
          <div class="col-12 shopping-cart-container {{iabView ? 'shopping-cart-container-iab' : ''}}">
            <div class="shopping-cart p15">
              <div class="row pb-3" style="min-height: 70px">
                <div class="{{ data && data.length ? 'col-4' : 'col-6' }}">
                  <img src="{{ garage?.bannerImageUrl || garage?.desktopBannerImageUrl }}" width="100%"
                    style="max-height: 171px" alt="Garage Banner" loading="lazy" />
                </div>
                <div class="garage-detail-info {{
                    data && data?.length ? 'col-8' : 'col-6'
                  }}">
                  <b>{{ garage?.name }}</b>
                  <p class="description" *ngIf="!data || !data.length">
                    {{ garage?.description }}
                  </p>
                  <p class="read-more-btn clickable" *ngIf="!data || !data.length" (click)="onClickViewMore()">
                    Read more
                  </p>
                  <div class="garage-dire mt-4">
                    <span class="distance mr-2">{{ garage?.distance }}KM</span>
                  </div>
                </div>
              </div>
              <div class="col-12 line-below mb-3" *ngIf="garage"></div>

              <div class="cart-tile">
                <a class="clear-all clickable" (click)="clickClearCart()">Clear All</a>
                <h3><strong>Service Items</strong></h3>
              </div>
              <ul class="my-0 car-product-content {{page == 'checkout' ? 'car-product-content-checkout' : '' }}">
                <li class="line-below pb-3 pt-3" *ngFor="let item of data">
                  <div class="row">
                    <div class="col-4">
                      <img src="{{ item.imageUrl || item.desktopImageUrl }}" alt="{{item.title}}" width="100%" />
                    </div>
                    <div class="col-8 cart-product-item">
                      <h3>
                        <strong>{{ item.title }}</strong>
                      </h3>
                      <div class="voucher-code" *ngIf="item.voucherCode">
                        <span>{{ item.voucherCode }}</span>
                      </div>
                      <div class="product-price mt-2 mb-1">
                        <span class="mr-1" *ngIf="item.isFromPrice">From</span>
                        {{ item.priceAfterDiscount | pricingFormat }}
                      </div>
                      <i class="fa fa-minus-circle fa-circle-minus clickable" (click)="clickRemoveProducts(item)"></i>
                      <span class="quantity">{{ item.quantity }}</span>
                      <i class="fa fa-plus-circle fa-circle-plus clickable {{
                          !item.isActive || item.quantity >= item.cartLimitMax
                            ? 'plus-btn-disable'
                            : ''
                        }}" (click)="clickAddProducts(item)"></i>
                    </div>
                  </div>
                </li>

                <li class="line-below pb-3 pt-3" *ngIf="useGovalet && goValet">
                  <div class="row">
                    <div class="col-4">
                      <img src="../assets/images/icon-valet.png" alt="GoValet" width="100%" />
                    </div>
                    <div class="col-8 cart-product-item">
                      <h3>
                        <strong>Estimate GoValet Fee</strong>
                      </h3>
                      <div class="product-price mb-2">
                        {{ goValet.fee || 0 | pricingFormat }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="checkout">
              <div class="row promo-code" *ngIf="page == 'detail'">
                <div class="col-12 pt-2 service-remark">
                  <textarea rows="3" placeholder="Add Service Remark (Optional)" [(ngModel)]="remark"></textarea>
                  <div class="line pt-2 w-100"></div>
                </div>
                <div class="col-6 py-1">
                  <!-- <i class="fa-sharp fa-solid fa-ticket fa-ticket-alt"></i> -->
                  <input class="form-control-sm" type="text" [(ngModel)]="voucherCode"
                    placeholder="Insert Voucher Code" />
                </div>
                <div class="col-6 apply-btn-wrapper">
                  <span class="apply-voucher-btn clickable" (click)="clickClaimVoucher()">Apply<i
                      class="fa fa-arrow-right ml-2 pr-3"></i></span>
                </div>
              </div>
              <div class="row information px-3">
                <div class="d-flex">
                  <i class="fa fa-info-circle mr-2 mt-1" aria-hidden="true"></i>
                  <p class="mb-0">
                    Don’t worry about payment now. Payment is only required after the car service is completed.
                  </p>
                </div>
              </div>
              <div class="balance" *ngIf="page =='detail'">
                <p>Total</p>
                <span>{{ estimateCost() | pricingFormat }}</span>
              </div>
              <div class="row" *ngIf="page =='detail'">
                <div class="btn-checkout clickable" (click)="onClickCheckoutClick()">
                  <a>Continue</a>
                </div>
              </div>

              <div class="row" *ngIf="page =='checkout'">
                <div class="btn-checkout estimate-cost">
                  <span style="font-size: 14px;">Estimate Cost</span>
                  <span style="font-size: 24px;">{{ estimateCost() | pricingFormat }}</span>
                </div>
              </div>
            </div>
          </div>

          <app-modal-view-garage-detail [modalId]="modalViewGarageDetail" [data]="garage">
          </app-modal-view-garage-detail>
          <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }">
          </ngx-loading>
        </div>
      </div>
    </div>
  </div>
</div>