<div class="modal" [id]="modalId" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="content">
        <span class="btn-close">
          <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
        </span>
        <div class="text-center" style="font-weight: bold;font-size: 18px;color: #0D7663;" *ngIf="!isRequestOTP">
          Enter your mobile number to continue
        </div>
        <div class="text-center" style="font-weight: bold;font-size: 18px;color: #0D7663;" *ngIf="isRequestOTP">
          OTP sent to
        </div>

        <!-- <div class="mb-3 text-center" style="font-size: 12px" *ngIf="!isRequestOTP">Enter your mobile no to continue</div> -->
        <div class="mb-3 text-center" style="font-size: 12px" *ngIf="isRequestOTP">{{phoneNumber}}</div>
    
        <div *ngIf="!isRequestOTP">
          <input style="border-radius: 20px;" class="form-control mt-2" type='text'
            [(ngModel)]="phoneNumber"
            placeholder="Mobile No. (Exp: 012 255 5588)" />
        </div>

        <!-- requeset OTP; -->
        <div *ngIf="isRequestOTP">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num1" class="int-number" maxlength="1">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num2" class="int-number" maxlength="1">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num3" class="int-number" maxlength="1">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num4" class="int-number" maxlength="1">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num5" class="int-number" maxlength="1">
          <input type="number" oninput="this.value=this.value.slice(0, this.maxLength)" (keyup)="validate($event)"
            [(ngModel)]="num6" class="int-number" maxlength="1">
        </div>
    
        <button
          (click)="requestOTP()"
          *ngIf="!isRequestOTP"
          class="form-control btn-confirm mt-3" style="border-radius: 20px;">
          Request OTP
        </button>

        <button
          *ngIf="isRequestOTP"
          (click)="verifyOTP()" 
          [disabled]="!isEnable"
          class="form-control btn-confirm mt-3" style="border-radius: 20px;" >
          Submit
        </button>
        <div class="mb-3 mt-3 text-center" *ngIf="isRequestOTP">
          <span style="font-weight: bold;font-size: 12px;color: #0D7663; text-decoration: underline; cursor: pointer;"
          (click)="resendOTP()">Resend OTP</span>
        </div>
      </div>

      <div>
        <div id="captcha_element" class="g-recaptcha" [attr.data-sitekey]="siteKey"></div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>