<div class="modal" [id]="modalId" role="dialog" style="z-index: 99999999;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header dialog-header">
        <h3 class="modal-title m-auto">Confirm</h3>
        <button type="button" class="close m-0 p-0" style="color: #fff; opacity: 1;"
          (click)="click('cancel')">&times;</button>
      </div>
      <div class="modal-body">
        <h4>{{confirmContent}}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="click('cancel')">Cancel</button>
        <button type="button" class="btn btn-confirm" (click)="click('ok')">Ok</button>
      </div>
    </div>
  </div>
</div>