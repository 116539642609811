import { Component, Input, OnInit } from '@angular/core';
import { BookingsService } from 'src/app/shared/services/bookings/bookings.service';
declare var $: any;
@Component({
  selector: 'app-modal-rating',
  templateUrl: './modal-rating.component.html',
  styleUrls: ['./modal-rating.component.scss']
})
export class ModalRatingComponent implements OnInit {
  @Input() modalId = '';
  currentRating = 0;
  comment = ''
  isLoading = false
  constructor(private bookingService: BookingsService) { }

  ngOnInit(): void {
  }

  cancel() {
    this.hide()
  }

  hide() {
    $(`#${this.modalId}`).modal("hide");
  }

  highlightPreviousStars(stars: number) {
    // Highlight the current and previous stars
    const currentStar = document.querySelector(`.star:nth-child(${stars})`);
    if (currentStar) {
      currentStar.classList.add('active');
      let previousStars = currentStar.previousElementSibling;
      while (previousStars) {
        previousStars.classList.add('active');
        previousStars = previousStars.previousElementSibling;
      }
    }
  }

  resetStars() {
    // Remove the active class from all stars except the ones that are part of the current rating
    const allStars = document.querySelectorAll('.star');
    allStars.forEach((star, index) => {
      if (index >= this.currentRating) {
        star.classList.remove('active');
      }
    });
  }

  setRating(stars: number) {
    // Set the rating and update the stars
    this.currentRating = stars;
    const allStars = document.querySelectorAll('.star');
    allStars.forEach((star, index) => {
      if (index < this.currentRating) {
        star.classList.add('active');
      } else {
        star.classList.remove('active');
      }
    });
  }

  async submit() {
    if (this.currentRating || this.comment) {
      const bookingId = localStorage.getItem('bookingIdPayment')
      try {
        const body = {
          star: this.currentRating,
          comment: this.comment,
          bookingId
        }
        this.isLoading = true
        await this.bookingService.submitRating(body).toPromise()
        localStorage.removeItem('bookingIdPayment')
        this.hide()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    }
  }

}
