<div class="modal" [id]="modalId" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header dialog-header">
        <h3 class="modal-title m-auto xl-text-bold text-center">Please select date</h3>
        <button type="button" class="close m-0 p-0" style="opacity: 1;" (click)="cancel()">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" #inputDate>
            <span>Date</span>
            <input type="text" class="form-control bg-white clickable" value="{{dateSelected | date: 'dd MMM yyyy'}}"
              readonly (click)="isShowCalendar.date = !isShowCalendar.date">
          </div>
          <div class="col-12 p-0 text-center calendar-date" *ngIf="isShowCalendar.date" #calendaDate>
            <p-calendar [(ngModel)]="dateSelected" [style]="pCalendarStyle" placeholder="Select Date" (onSelect)="isShowCalendar.date = false; onselectDate()"
              dateFormat="yy/mm/dd" [readonlyInput]="true" [showIcon]="true" [showTime]="false" [inline]="true"
              [minDate]="minDate">
            </p-calendar>
          </div>

          <!-- <div class="col-12 mt-3" #inputTime>
            <span>Time</span>
            <input type="text" class="form-control bg-white clickable" placeholder="Choose Time"
              [(ngModel)]="timeSelected.name" readonly (click)="isShowCalendar.time = !isShowCalendar.time">
          </div>
          <div class="col-12 p-0 calendar-time clickable" *ngIf="isShowCalendar.time" #calendaTime>
            <ul>
              <li *ngFor="let item of TIME_OPTIONS" (click)="timeSelected = item; isShowCalendar.time = false"
                [ngClass]="{'active': timeSelected.name == item.name}">
                <span>{{item.name}}</span>
              </li>
            </ul>
          </div> -->

          <div class="col-12 mt-3">
            <button class="btn btn-continue" (click)="continue()">
              CONTINUE
            </button>
          </div>

          <div class="col-12 mt-3 row">
            <div class="col-5 col-lg-6 gocar-app-img">
              <img src="../assets/images/gocar-app-img.png" alt="">
            </div>
            <div class="col-7 col-lg-6 download-app">
              <strong class="mt-2 xl-text">Download GoCar Mobile App <br>For More Benefits</strong>
              <a href="{{DOWNLOAD.IOS}}" target="_blank" class="clickable">
                <img class="mb-3 mr-1" src="../assets/images/apple.png" alt="Apple Store" />
              </a>
              <a href="{{DOWNLOAD.ANDROID}}" target="_blank" class="clickable">
                <img class="mb-3" src="../assets/images/android.png" alt="Android Play Store" />
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>