import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class GaragesService extends BaseService {
  prefixURL = 'garages';

  garageIdSelected = '';
  productSelected: any = [];

  constructor(
    public override http: HttpClient,
    private utilsService: UtilsService,
    private usersService: UsersService
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getGaragesNearBy(lat: number, lng: number, page: number, categoryIds: any = '', date = '', time = '', labels: boolean | string[] = [], tagIds = [], radius = ''): Observable<any> {
    return this.get(`${this.prefixURL}/garages-near/${lat}/${lng}?page=${page}&categoryId=${categoryIds}&date=${date}&time=${time}&labels=${labels}&tagIds=${tagIds}&radius=${radius}`);
  }

  getById(garageId: string, params: any) {
    return this.get(`${this.prefixURL}/garage-with-location/${garageId}`, params)
  }

  getByKey(garageKey: string, params: any) {
    return this.get(`${this.prefixURL}/garage-with-location-and-key/${garageKey}`, params)
  }

  getProducts(categoryId: string, garageId: string) {
    return this.get(`products?categoryId=${categoryId}&garageId=${garageId}`)
  }

  sessionId: string = ''
  getProductsOnLocalstorage() {
    const data = this.utilsService.getLocalStorage('gogarage_products');

    if (!data) {
      return {};
    }

    return JSON.parse(data) || {};
  }

  setProductsOnLocalstorage(data: any) {
    const storage = this.getProductsOnLocalstorage()
    if (data.garageId && (!storage || storage.garageId != data.garageId || !this.sessionId)) {
      this.sessionId = this.utilsService.createUniqueString()
    }

    this.updateBookingPending({ sessionId: this.sessionId, status: 'pending', ...data })

    return this.utilsService.setLocalStorage('gogarage_products', JSON.stringify(data));
  }

  setProductsOnLocalstorageWithOutUpdateBookingPending(data: any) {
    return this.utilsService.setLocalStorage('gogarage_products', JSON.stringify(data));
  }

  removeProductsOnlocalStorage() {
    return this.utilsService.removeLocalStorage('gogarage_products');
  }

  updateBookingPending(data: any) {
    const user = this.usersService.getUserLocal()
    if (user) {
      data.userId = user.userId
      data.userName = user.firstName + ' ' + user.lastName
      data.email = user.email
      data.telephone = user.phoneNumber
    }
    console.log("updateBookingPending ====> ", data)
    return this.post(`bookings/booking-pending`, data).toPromise()
  }

  getTimeSlotAvailable(id: any, date: any) {
    return this.get(`${this.prefixURL}/${id}/time-slots-available/${date}?source=web`);
  }

  getAQuote(body: any, reCaptchaToken?: any) {
    return this.post(`${this.prefixURL}/get-quote`, body);
  }

  getAllTags() {
    return this.get(`${this.prefixURL}/get-all-tags`);
  }

  getAllLabels() {
    return this.get(`${this.prefixURL}/all-labels`);
  }
}
