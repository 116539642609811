import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';


@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private window;

  constructor(private windowRef: WindowReferenceService, @Inject(PLATFORM_ID) platformId: Object) {
    const isBrowser = isPlatformBrowser(platformId);
    if (isBrowser) {
      this.window = windowRef.nativeWindow; // intialise the window to what we get from our window service
    }
  }

  private pingHome(obj: any) {
    if (obj) this.window.dataLayer.push(obj);
  }


  logPageView(url: string) {
    const hit = {
      // event: 'content-view',
      event: 'gtm.js',
      pageName: url
    };
    this.pingHome(hit);
  }

  logEvent(event: any, category?: any, action?: any, label?: any) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label
    }
    this.pingHome(hit);
  }

  logCustomDimensionTest(value: any) {
    const hit = {
      event: 'custom-dimension',
      value: value
    }
    this.pingHome(hit);
  }
}