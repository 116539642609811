import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingsService } from '../../services/bookings/bookings.service';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';

declare var $: any;

@Component({
  selector: 'app-modal-view-service-gallery',
  templateUrl: './modal-view-service-gallery.component.html',
  styleUrls: ['./modal-view-service-gallery.component.scss']
})
export class ModalViewServiceGalleryComponent implements OnInit {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();

  isLoading = false;
  attacts: any = [
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' },
    { url: 'https://staging2.gocar.my/upload/gogarage/bookings/8f9d9220-7444-11ed-ac47-c33bcf943a97/inspection/38a322e4-151e-4ec0-9eb4-5d326708aa46.jpeg' }
  ];

  constructor(
    private utilsService: UtilsService,
    private userService: UsersService,
    private bookingService: BookingsService
  ) { }

  ngOnInit() {

  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }
}
