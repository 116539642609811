import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  ROUTER = APP_ROUTES;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  backToHome() {
    this.router.navigate(['']);
  }

}
