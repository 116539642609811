<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="content">
                <span class="btn-close">
                    <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
                </span>
                <div class="row">
                    <div class="header-section col-12 line-below">
                        <strong>
                            Service Details
                        </strong>
                        <!-- <hr class="header-line"> -->
                    </div>

                    <div class="col-12 content-section" *ngIf="products && products.length">
                        <div class="row product-item line-below mt-2" *ngFor="let product of products">
                            <div class="col-12"><strong>{{product.title}}</strong></div>
                            <div class="col-12 mt-1" *ngIf="product.subtitle">{{product.subtitle}}</div>
                            <div class="col-12 mt-1"><strong>x{{product.count}}</strong></div>

                            <div class="col-12 mt-1 mb-1">
                                <span style="text-decoration: line-through; margin-right: 10px;" *ngIf="product.priceAfterDiscount != product.price">{{ product.price | pricingFormat }}</span>
                                <strong style="color: #0DC3A3;">{{ product.priceAfterDiscount | pricingFormat }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
