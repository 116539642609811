<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="content">
                <span class="btn-close">
                    <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
                </span>
                <div class="row">
                    <div class="header-section col-12">
                        <strong>
                            Garage Details
                        </strong>
                        <hr class="header-line">
                    </div>

                    <div class="col-12 mt-3" *ngIf="data">
                        <div class="row">
                            <div class="col-8">
                                {{ data.description }}
                            </div>
                            <div class="col-4">
                                <a href="{{data.bannerImageUrl}}" target="_blank">
                                    <img class="img-item" [src]="data.bannerImageUrl" width="100%" loading="lazy"
                                        alt="Garage Banner">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>