import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/shared/common/constants';
import { GaragesService } from '../../shared/services/garages/garages.service';
import { UtilsService } from '../../shared/services/utils/utils.service';
import * as _ from 'lodash';
import { ConfigsService } from 'src/app/shared/services/configs/configs.service';
import { APP_ROUTES } from 'src/app/shared/common/app-routes';
import { CategoriesService } from 'src/app/shared/services/categories/categories.service';
import { isPlatformBrowser } from '@angular/common';
import { PromotionsService } from 'src/app/shared/services/promotions/promotions.service';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  location = { lat: Number, lng: Number };
  garagesNearBy: any = [];
  categories: any = [];
  categorySelected: any = {};
  categoryIdSelected = '';
  otherParams = {};
  DOWNLOAD = CONSTANTS.DOWNLOAD

  dateSelected: any = {};
  MODAL_SELECT_DATE_TIME = 'mocal-calander-date-time';
  isLoading = false;
  banners: any = [];
  ROUTER = APP_ROUTES
  STICK_MENU = CONSTANTS.STICK_MENU
  showGoCarPrefer = { labelNames: [CONSTANTS.GOCAR_PREFERRED] }
  dataRating = [
    { rate: 5, sign: 'Lim Chee Leong', created: '2023-03-01', comment: 'GoCarGarage made servicing my car an absolute breeze! The process was simple, and the garage I chose did a fantastic job.' },
    { rate: 5, sign: 'Engine Ng', created: '2023-01-23', comment: 'I was really impressed by the level of transparency that GoCarGarage provides. I always knew what was happening with my car, and the updates were incredibly helpful.' },
    { rate: 5, sign: 'Nurul Aisyah binti Mohd Yusof', created: '2022-11-05', comment: 'Saya sangat berpuas hati dengan pengalaman saya menggunakan GoCarGarage. Platform ini sangat mudah digunakan, dan saya dapat mencari bengkel yang boleh dipercayai dengan cepat.' },
    { rate: 5, sign: 'Muhammad Amir bin Abdullah', created: '2022-01-10', comment: 'Kebolehan untuk membandingkan harga dan perkhidmatan yang ditawarkan oleh bengkel yang berbeza sangat membantu saya membuat keputusan yang bijak berdasarkan bajet dan keperluan kenderaan saya.' },
  ]
  redirectUrl: any
  modalLogin = 'modal-login-home';
  constructor(
    private utilsService: UtilsService,
    private garagesService: GaragesService,
    private categoryService: CategoriesService,
    private router: Router,
    private route: ActivatedRoute,
    private configsService: ConfigsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private promotionsService: PromotionsService
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    await this.getBanners();

    if (isPlatformBrowser(this.platformId)) {
      await Promise.all([
        this.getListCategory(),
        this.getGaragesNearBy()
      ]);
    }
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params && params['redirectUrl']
      console.log('this.redirectUrl', this.redirectUrl);

      if (this.redirectUrl) {
        this.showModal(this.modalLogin)
      }
    })
  }

  async getGaragesNearBy() {
    // skip on SSR
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    try {
      // this.isLoading = true;

      const location = await this.utilsService.getCurrentLocation();
      const result = await this.garagesService.getGaragesNearBy(
        location?.lat,
        location?.lng,
        0,
        this.categoryIdSelected
      ).toPromise();

      this.garagesNearBy = result && result.data && result.data.garages || [];
      // this.isLoading = false;
    } catch (error: any) {
      // this.isLoading = false;
      this.utilsService.showError("Can not get garages near by. Please try again later and make sure you allow location permission.");
    }
  }

  async getListCategory() {
    try {
      const result = await this.categoryService.getListCategories('').toPromise();

      if (!result || !result.data) {
        return;
      }

      this.categories = result.data;
    } catch (error: any) {
      this.utilsService.showError(error && error.message);
    }
  }

  pre() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft -= 250;
    }
  }

  next() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft += 250;
    }
  }

  onChooseGarage(item: any) {
    if (!this.categoryIdSelected) {
      this.categoryIdSelected = this.categories[0].id;
    }

    const category = this.categories[0]
    this.router.navigate([`garages/${item.key}`], {
      queryParams: { categoryId: category && category.key }
    })
  }

  onSelectCategory(item: any) {
    this.categorySelected = item
    this.categoryIdSelected = item.id;

    return this.router.navigate([`garages`], {
      queryParams: { categoryId: item && item.key }
    });
  }

  async getBanners() {
    try {
      this.isLoading = true;
      const result = await this.promotionsService.getPromotions('home').toPromise();

      this.banners = result && result.data || [];
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      this.utilsService.showError(error && error.message);
    }
  }

  clickFindNearestGarage() {
    this.router.navigate([`garages`])
  }

  onClickBookNow(category?: any, otherParams?: any) {
    this.otherParams = otherParams;
    this.categorySelected = category;
    // this.showModal(this.MODAL_SELECT_DATE_TIME)

    let queryParams: any = {}
    if (this.categorySelected && this.categorySelected.key) {
      queryParams.categoryId = this.categorySelected.key
    }
    if (this.otherParams) {
      queryParams = { ...queryParams, ...this.otherParams }
    }
    return this.router.navigate([`garages`], {
      queryParams: queryParams
    });
  }

  confirmBookAppointment(event: any) {
    if (event) {
      let queryParams = { ...event }
      if (this.categorySelected && this.categorySelected.key) {
        queryParams.categoryId = this.categorySelected.key
      }
      if (this.otherParams) {
        queryParams = { ...queryParams, ...this.otherParams }
      }
      return this.router.navigate([`garages`], {
        queryParams: queryParams
      });
    }
    return true
  }

  onClickFindAllFAQ() {
    try {
      let newWin = window.open(`https://gocarhelp.zendesk.com/hc/en-us/sections/4403210234265-General`, '_blank');

      if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
        alert('Popup Blocker is enable. Please allow popups for this website!');
      }
    } catch (error: any) {
      return this.utilsService.showError(error.message);
    }
  }

  onSelecteSlide(item: any) {
    if (item.linkToType == 'internal' && item.linkToValue) {
      if (item.linkToValue == 'home') {
        this.router.navigate(['/'])
      } else {
        this.router.navigateByUrl(APP_ROUTES.GARAGE + '?categoryId=' + item.linkToValue)
      }
    }

    if (item.linkToType == 'external' && item.linkToValue) {
      try {
        let newWin = window.open(item.linkToValue, '_blank');

        if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
          alert('Popup Blocker is enable. Please allow popups for this website!');
        }
      } catch (error: any) {
        return this.utilsService.showError(error.message);
      }
    }
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }
}
