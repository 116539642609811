import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { GaragesService } from '../../services/garages/garages.service';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';
import { VouchersService } from '../../services/vouchers/vouchers.service';

declare var $: any;

@Component({
  selector: 'app-modal-view-shopping-cart',
  templateUrl: './modal-view-shopping-cart.component.html',
  styleUrls: ['./modal-view-shopping-cart.component.scss']
})
export class ModalViewShoppingCartComponent implements OnInit {
  @Input() modalId = '';
  @Input() data: any = [];
  @Input() garage: any = {};
  @Input() page = 'detail'
  @Input() voucherCode = '';
  @Input() remark = '';
  @Input() goValet: any
  @Input() useGovalet = 0;

  @Output() removeProducts = new EventEmitter<any>();
  @Output() addProducts = new EventEmitter<any>();
  @Output() clearCart = new EventEmitter<any>();
  @Output() showLoginPopup = new EventEmitter<any>();
  @Output() mapProducts = new EventEmitter<any>();

  modalViewGarageDetail = 'model-view-garage-detail';
  isLoading = false;
  voucherData: any;
  garageKey = '';
  dateTimeSelected: any;
  iabView: any;

  constructor(
    private usersService: UsersService,
    private voucherService: VouchersService,
    private utilsService: UtilsService,
    private gogarageService: GaragesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .subscribe(([pathParams, queryParams]) => {
        this.garageKey = pathParams.get('garageId') || ''
        this.dateTimeSelected = queryParams.get('date') ? { date: queryParams.get('date') } : null
      });

      this.iabView = this.utilsService.isGocarApp;
  }

  ngOnInit() {

  }

  hide() {
    $(`#${this.modalId}`).modal("hide");
  }

  clickRemoveProducts(item: any) {
    this.removeProducts.emit(item);
  }

  clickAddProducts(item: any) {
    this.addProducts.emit(item);
  }

  async clickClaimVoucher() {
    if (!this.voucherCode) {
      return;
    }

    const userLogin = this.usersService.getUserLocal();

    if (!userLogin) {
      this.hide();
      this.showLoginPopup.emit();
      return;
    }

    let body = {
      userId: userLogin.userId,
      garageId: this.garage.id,
      code: this.voucherCode
    }

    this.isLoading = true;
    try {
      const result = await this.voucherService.checkVoucher(body).toPromise();
      this.isLoading = false;
      this.voucherCode = '';
      if (!result || !result.data) {
        return;
      }

      this.voucherData = result.data;

      this.replaceProductSelected(result.data.products);
    } catch (error: any) {
      this.isLoading = false;
      this.utilsService.showError(error.message);
    }

  }

  onClickCheckoutClick() {
    if (!this.data || !this.data.length) {
      return this.utilsService.showError('Your Cart is empty!');
    }
    const isFreeGoValet = this.voucherData && this.voucherData.isFreeGoValet || 0;
    this.hide();

    this.router.navigate([`garages/${this.garageKey}/checkout`], {
      queryParams: {
        isFreeGoValet,
        ...this.dateTimeSelected,
        remark: this.remark
      }
    })
  }

  estimateCost() {
    let total = 0
    if (!this.data || !this.data.length) {
      return total;
    }

    this.data.forEach((element: any) => {
      total += ((element.priceAfterDiscount || 0) * element.quantity)
    });

    if (this.goValet) {
      total += this.goValet.fee || 0
    }

    return total
  }

  clickClearCart() {
    this.clearCart.emit();
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  onClickViewMore() {
    this.showModal(this.modalViewGarageDetail);
  }

  replaceProductSelected(products: any) {
    if (!products || !products.length) {
      return;
    }

    products.forEach((product: any) => {
      product.quantity = 1;
    });

    this.gogarageService.setProductsOnLocalstorage({
      garageId: this.garage.id,
      products,
    });

    this.mapProducts.emit();
  }
}
