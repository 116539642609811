import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-modal-calander',
  templateUrl: './modal-calander.component.html',
  styleUrls: ['./modal-calander.component.scss']
})
export class ModalCalanderComponent implements OnInit, OnChanges {
  @Input() modalId = '';
  @Input() dateSelectedDefault = new Date();
  @Output() choose = new EventEmitter<any>();

  minDate = new Date()
  dateSelected: any;

  constructor() { }

  ngOnInit() {
   this.dateSelected = new Date(this.dateSelectedDefault);
  }

  ngAfterViewInit() {
    if (this.dateSelectedDefault) {
      this.dateSelected = new Date(this.dateSelectedDefault);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dateSelectedDefault) {
      this.dateSelected = new Date(this.dateSelectedDefault);
    }
  }


  confirm() {
    let data = moment(this.dateSelected).format("YYYY-MM-DD");
    this.choose.emit(data);
    this.hide();
  }

  cancel() {
    this.choose.emit();
    this.hide()
  }

  hide() {
    $(`#${this.modalId}`).modal("hide");
  }
}
