import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CONSTANTS } from '../../common/constants';
import { BookingsService } from '../../services/bookings/bookings.service';
import { GocarDialogService } from '../../services/gocar-dialog/gocar-dialog.service';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ModalIframeComponent } from '../modal-iframe/modal-iframe.component';
declare var $: any;
@Component({
  selector: 'app-modal-invoice',
  templateUrl: './modal-invoice.component.html',
  styleUrls: ['./modal-invoice.component.scss']
})
export class ModalInvoiceComponent implements OnInit, OnChanges {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();
  MODAL_CONFIRM_PAYMENT_NOW = 'modal-confirm-payment-now';
  SUBSCRIPTION_TERM_CONDITION_URL = 'https://gocarhelp.zendesk.com/hc/en-us/articles/4403709699993-GoCar-Garage-Terms-and-Conditions'

  isLoading = false;
  isShowCredit = false;
  isShowOnlineBanking = false;
  isShowBNPL = false
  promoCode: any = '';
  isAgree1: any = false;
  isAgree2: any = false;
  paymentMethod: any;
  pricing: any;
  INVOICE_GENERATED = CONSTANTS.BOOKING_STATUS.INVOICE_GENERATED.value;
  creditCards = [
    {
      id: '2',
      name: "Credit Card / Debit Card"
    }
  ]

  onlineBankings = [
    {
      paymentId: '6',
      name: "Maybank"
    }
  ]

  bnplMethods = [
    // {
    //   id: '891',
    //   name: "ATOME BNPL"
    // },
    {
      id: '523',
      name: "GrabPay"
    }
  ]

  constructor(
    private utils: UtilsService,
    private bookingService: BookingsService,
    private userService: UsersService,
    private gocarDialogService: GocarDialogService
  ) { }

  ngOnInit() {
    this.initPaymentMethod()
  }

  ngOnChanges() {
    if (this.data) {
      this.getPrice()
    }
  }

  async initPaymentMethod() {
    try {
      const rs = await this.bookingService.getPaymentMethod().toPromise()

      if (rs && rs.data) {
        this.onlineBankings = rs.data.filter((item: any) => item.type == 'online-banking')
      }

    } catch (error) {

    }
  }

  getGarageCost() {
    if (this.pricing) {
      return Number(this.pricing.bookingFee || 0) + Number(this.pricing.govaletFee || 0)
    } else if (this.data && this.data.invoices.length) {
      return Number(this.data.invoices[0].totalPrice) + Number(this.data.invoices[0].govaletFee || 0)
    } else {
      return 0
    }
  }

  getTotalCost() {
    if (this.pricing) {
      return Number(this.pricing.finalPrice || 0)
    } else if (this.data && this.data.invoices.length) {
      return Number(this.data.invoices[0].totalPrice) + Number(this.data.invoices[0].govaletFee || 0)
    } else {
      return 0
    }
  }

  async getPrice() {
    if (!this.promoCode) {
      return;
    }
    const userInfo = this.userService.getUserLocal();
    let data = {
      bookingGogarageId: this.data.id,
      promoCode: this.promoCode,
      userId: userInfo && userInfo.userId
    }
    try {
      this.isLoading = true;
      const result = await this.bookingService.calculatePriceBooking(data).toPromise();
      this.pricing = result && result.data;
      this.isLoading = false;
    } catch (error: any) {
      console.log(error);
      this.isLoading = false;
      this.utils.showError(error && error.message);
    }
  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.promoCode = null;
    this.pricing = null;
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }

  paymentNow() {
    if (!this.isAgree1 || !this.isAgree2) {
      this.utils.showError('To proceed, please understand and agree the terms and conditions.')
      return;
    }
    if (!this.paymentMethod) {
      this.utils.showError('Please choose your payment method.')
      return;
    }
    this.showModal(this.MODAL_CONFIRM_PAYMENT_NOW)
  }

  async confirmPaymentNow(option: any) {
    if (option === 'ok') {
      try {
        const param = {
          paymentMethodId: this.paymentMethod,
          promoCodeId: this.pricing && this.pricing.discount && this.pricing.discount.promoId || '',
          bookingId: this.data.id,
          finalPrice: this.getTotalCost(),
          invoiceId: this.data.invoices[0].id,
          discount: this.pricing && this.pricing.discount && this.pricing.discount.value || 0,
          cost: this.getGarageCost()
        }
        this.isLoading = true;
        const result = await this.bookingService.generateHoldPayment(param).toPromise();
        localStorage.setItem('bookingIdPayment', this.data.id)
        if (result && result.data) {
          window.open(result.data, '_self');
        }
        setTimeout(() => {
          this.isLoading = false
        }, 10000);
        this.isLoading = false;
      } catch (error: any) {
        console.log(error);
        this.isLoading = false;
        this.utils.showError(error && error.message);
      }


      this.isAgree1 = false;
      this.isAgree2 = false;
      this.promoCode = '';
      $(`#${this.modalId}`).modal("hide");
    }
  }

  openTermAndConditions() {
    window.open(this.SUBSCRIPTION_TERM_CONDITION_URL, '_blank')
  }

  // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  openPdf(invoice: any) {
    if (this.utils.isGocarApp) {
      this.gocarDialogService.show(ModalIframeComponent, {
        data: invoice,
        showHeader: false,
        width: '90%',
        contentStyle: {
          "height": "70vh", "overflow": "auto", "border-radius": '10px', "padding": '15px'
        },
      }).then(result => {
        console.log(result)
      })
    } else {
      const fileUrl = invoice.pdfUrl
      window.open(fileUrl, "_blank")
    }
  }
}
