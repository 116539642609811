import { BaseService } from "../base.service";
import { environment as config } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PromotionsService extends BaseService {
  prefixUrl = 'promotions';

  constructor(
    public override http: HttpClient
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getPromotions(page: string) {
    return this.get(`${this.prefixUrl}?page=${page}`);
  }
}