<div style="width: 100%; height: 100%;">
    <span class="btn-close">
        <i class="fa fa-times p-2" aria-hidden="true" (click)="closeDialog()"></i>
    </span>
    <div class="spinner-border" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </div>
    <div style="width: 100%; height: 100%;" *ngIf="data && data.typeFile == 'application/pdf'">
        <iframe class="pt-4" [src]="fileUrl | safeResourceUrl" (load)="loadEvent($event)" id="iframe_pdf" #iframe_pdf
            frameBorder="0" scrolling="auto" height="100%" width="100%">
        </iframe>
    </div>
    <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;"
        *ngIf="data && data.typeFile != 'application/pdf'">
        <img [src]="data.pdfUrl" style="width: 100%;">
    </div>
</div>