<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="content">
                <span class="btn-close">
                    <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
                </span>
                <div class="row">
                    <div class="header-section">
                        <strong>
                            Quotation
                        </strong>
                        <span>{{data?.quotation?.created | dateTimeFormat: 'DD/MM/YYYY, HH:mm A'}}</span>
                        <a (click)="openPdf(data && data?.quotation)">
                            View Quotation Details
                        </a>
                    </div>
                    <div class="content-section">
                        <strong>Image Attached</strong>
                        <div class="images"
                            *ngIf="data?.quotation && data?.quotation.inspections && data?.quotation.inspections.length">
                            <div class="{{photo.attachmentType != 'video' ? 'col-6': 'col-12' }} col-lg-2 mt-2"
                                *ngFor="let photo of data?.quotation.inspections">
                                <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType != 'video'">
                                    <img class="img-item" [src]="photo.url" loading="lazy" alt="Uploaded Photo">
                                </a>
                                <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType == 'video'">
                                    <video controls width="100%" controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar">
                                        <source [src]="photo.url">
                                    </video>
                                </a>
                            </div>
                        </div>

                        <strong style="display: block; margin-top: 20px;">Description</strong>
                        <span>{{data?.quotation?.inspectionsDescription}}</span>
                        <div class="cost-estimate">
                            <strong>Estimated Cost</strong>
                            <span class="cost">
                                ≈ {{data?.quotation?.totalPrice | pricingFormat}}
                            </span>
                        </div>
                    </div>
                    <div class="footer-section" *ngIf="data && data?.quotation && data?.quotation.status == 'pending'">
                        <button (click)="approval()" class="btn btn-approval" style="border-radius: 5px;">
                            Approval
                        </button>
                        <button (click)="reject()" class="btn btn-reject" style="border-radius: 5px;">
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }">
    </ngx-loading>
</div>

<app-modal-confirm-common [modalId]="MODAL_CONFIRM_APPOVAL_QUOTATION" [confirmContent]="'Are you sure want to approve?'"
    (choose)="confirmApproval($event)">
</app-modal-confirm-common>
<app-modal-confirm-common [modalId]="MODAL_CONFIRM_REJECT_QUOTATION" [confirmContent]="'Are you sure want to reject?'"
    (choose)="confirmReject($event)">
</app-modal-confirm-common>