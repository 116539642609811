import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-iframe',
  templateUrl: './modal-iframe.component.html',
  styleUrls: ['./modal-iframe.component.scss']
})
export class ModalIframeComponent implements OnInit {
  fileUrl: any
  isLoading: boolean = false
  data: any = {}
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.data = this.config.data
      if (this.data && this.data.typeFile == 'application/pdf') {
        const encodedPdfUrl = encodeURIComponent(this.data && this.data.pdfUrl);
        // this.fileUrl = 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + url + '#toolbar=0&scrollbar=0'
        this.fileUrl = `https://docs.google.com/gview?url=${encodedPdfUrl}&embedded=true`
        console.log("fileUrl ====> ", this.fileUrl)

        // const iframe_window = document.getElementById("iframe_pdf");
        // if (iframe_window) {
        // iframe_window.style.display = "block";
        //   // iframe_window.src = google_link + encoded_pdf_link + google_param;
        // }
      }
    }
  }

  ngAfterViewInit() {

  }

  closeDialog() {
    this.ref.close();
  }

  loadEvent(e: any) {
    console.log("i'm loadEvent iframe ===> ", e)
    // if (this.isLoading) {
    //   this.isLoading = this.loadingComponent.hideLoading()
    // } else {
    //   this.isLoading = this.loadingComponent.showLoading()
    // }
    this.isLoading = !this.isLoading
  }
}
