<app-main-layout [page]="ROUTER.HOME">
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let item of banners; let i = index">
        <img class="banner-image d-none d-md-block w-100" src="{{ item.desktopImageUrl || item.imageUrl }}"
          alt="Banner" (click)="onSelecteSlide(item)">
        <img class="banner-image d-block d-md-none w-100" src="{{ item.imageUrl || item.desktopImageUrl }}"
          alt="Banner" (click)="onSelecteSlide(item)">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </button>
  </div>
  <!--section>
    <div class="row">
      <div class="d-none d-md-block col-12">
        <img src="../assets/images/master_banner_1.png" width="100%" alt="Banner" loading="lazy" />
      </div>
      <div class="d-block d-md-none col-12">
        <img src="../assets/images/master_banner_2.png" width="100%" alt="Banner" loading="lazy"/>
      </div>
    </div>
  </section-->
  <section class="fix-nav sticky-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <ul>
            <li *ngFor="let item of STICK_MENU">
              <a *ngIf="item.region || item.blank" href="{{
                  item.region ? item.href + '#' + item.region : item.href
                }}" target="{{ item.blank ? '_blank' : '' }}">
                {{ item.name }}
              </a>
              <a *ngIf="!item.region && !item.blank" routerLink="/{{item.href}}">
                {{ item.name }}
              </a>
            </li>
            <li>
              <a class="btn btn-gredient normal-text clickable mt-0 font-weight-bold" routerLink="/{{ROUTER.GARAGE}}">Book an
                Appointment</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="periodic-service" id="ourservice" #ourservice>
    <div class="s-title pt-5 px-3">
      <h1><strong>Periodic Oil Change Service Packages</strong></h1>
      <p>
        We are delighted to announce the partnership with TotalEnergies,
        customers can service at partner GoCar Garages which officially offer
        TotalEnergies lubricants
      </p>
    </div>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-lg-8 col-md-11 bg-white service-package-banner mx-3 row">
          <div class="col-lg-4 col-md-12 text-lg-center">
            <span>
              <i class="fas fa-check-circle mr-2"></i>
              Mineral Package
            </span>
          </div>
          <div class="col-lg-4 col-md-12 text-lg-center">
            <span>
              <i class="fas fa-check-circle mr-2"></i>
              Semi-Synthetic Package
            </span>
          </div>
          <div class="col-lg-4 col-md-12 text-lg-center">
            <span>
              <i class="fas fa-check-circle mr-2"></i>
              Fully-Synthetic Package
            </span>
          </div>
        </div>
      </div>
      <div class="text-center d-lg-none d-block"><a class="btn btn-gredient normal-text"
          (click)="onClickBookNow(categories[0])">Book an Appointment</a></div>
      <ul class="row text-center mt-4 justify-content-center">
        <li class="col-lg-4 col-md-4 mb-3">
          <div class="bg-white periodic-service-item">
            <h3 class="standard-service-title mb-0 p-2">Enduro</h3>
            <img src="../assets/images/periodic-services/standard.jpg" alt="Premium Semi Package" />
            <span class="d-block">From</span>
            <h2 class="text-green-bold"><strong>RM98.00</strong></h2>

            <ul class="mt-3 pl-3 text-left list-inline-item">
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>4L Enduro Iridium</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Oil filter</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Drain plug washer</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Labour charges</li>
            </ul>
            <p class="sm-text mt-3 px-lg-3 px-2">** Surcharge applies for additional engine oil (all cars) and original
              oil
              filter (continental cars)</p>
            <a class="btn btn-gredient normal-text" (click)="onClickBookNow(categories[0])">Book an Appointment</a>
          </div>
        </li>
        <li class="col-lg-4 col-md-4 mb-3">
          <div class="bg-white periodic-service-item">
            <h3 class="premium-service-title mb-0 p-2">TotalEnergies</h3>
            <img src="../assets/images/periodic-services/premium.png" alt="Premium Semi Package" />
            <span class="d-block">From</span>
            <h2 class="text-green-bold"><strong>RM168.00</strong></h2>

            <ul class="mt-3 pl-3 text-left list-inline-item">
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>4L Total Quartz</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Oil filter</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Drain plug washer</li>
              <li><i class="fa fa-check mr-2 text-green-bold" aria-hidden="true"></i>Labour charges</li>
            </ul>
            <p class="sm-text mt-3 px-lg-3 px-2">** Surcharge applies for additional engine oil (all cars) and original
              oil
              filter (continental cars)</p>
            <a class="btn btn-gredient normal-text" (click)="onClickBookNow(categories[0], showGoCarPrefer)">Book an
              Appointment</a>
          </div>
        </li>
      </ul>
    </div>

    <section class="product-highlight py-5 my-5">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center highlight-banner">
            <img src="../assets/images/title-banner.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 slide-content">
            <ul class="garage-card mt-4" id="car-carousell-list">
              <li>
                <img src="../assets/images/img-prod-highlight-01.png" />
                <div class="bg-white px-3 pt-3">
                  <p>Service your car with <b>TotalEnergies</b> fully-synthetic or semi-synthetic oil change</p>
                  <a href="https://www.gocargarage.my/garages/gocar-garage-pj?categoryId=periodic-service" target="_blank"
                    class="btn btn-gredient normal-text">TotalEnergies Service Package</a>
                  </div>
                  </li>
                  <li>
                    <img src="../assets/images/img-prod-highlight-03.png" />
                    <div class="bg-white px-3 pt-3">
                      <p><b>Free labour & free alignment & balancing</b> for change two (2) pieces and above</p>
                  <a href="https://www.gocargarage.my/garages/gocar-garage-pj?categoryId=tyre-service" target="_blank"
                    class="btn btn-gredient normal-text">Equip New Tyres Now!</a>
                  </div>
                  </li>
                  <li>
                    <img src="../assets/images/img-prod-highlight-04.png" />
                    <div class="bg-white px-3 pt-3">
                  <p>Complete <b>bleeding</b> and <b>replacement</b> of brake fluid replacement and <b>cleaning</b> of
                    front and rear brakes</p>
                  <a href="https://www.gocargarage.my/garages/gocar-garage-pj?categoryId=brake-repair-maintenance" target="_blank"
                    class="btn btn-gredient normal-text">Brake Service Standard Package</a>
                  </div>
                  </li>
                  <li>
                    <img src="../assets/images/img-prod-highlight-05.png" />
                    <div class="bg-white px-3 pt-3">
                  <p>For different type of cars’ battery with <b>12 months of warranty period or 20,000km</b> </p>
                  <a href="https://www.gocargarage.my/garages/gocar-garage-pj?categoryId=battery-inspection-replacement" target="_blank"
                    class="btn btn-gredient normal-text">Revive Your Battery</a>
                  </div>
                  </li>
                  <li>
                    <img src="../assets/images/img-prod-highlight-02.png" />
                    <div class="bg-white px-3 pt-3">
                      <p><b>Car Accessories Promo</b> of front wiper, U-hook, 100% natural rubber wiper blade</p>
                  <a href="https://www.gocargarage.my/garages/gocar-garage-pj?categoryId=car-accerssories" target="_blank"
                    class="btn btn-gredient normal-text">Shop Now for Upgrades</a>
                  </div>
                  </li>
                  </ul>
                  <div class="pre-next" style="font-size: 18px">
                    <a (click)="pre()">
                      <span><i class="fa fa-angle-left"></i></span>
                    </a>
                    <a (click)="next()">
                      <span><i class="fa fa-angle-right"></i></span>
                    </a>
                  </div>
                  </div>
                  </div>
                  </div>
    </section>
    <div class="other-services mt-5 pb-5">
      <div class="s-title">
        <h1><strong>Other Available services</strong></h1>
        <p>We offer a range of other services including:</p>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-6 clickable category-item" *ngFor="let item of categories"
            (click)="onSelectCategory(item)">
            <div class="bg-white text-center">
              <img src="{{ item.website_image }}" alt="{{ item.name }}" />
              <strong class="cat-name">{{ item.name }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container-fluid garage-map">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-12">
          <div class="bg-white">
            <h2 class="pl-lg-5 pr-lg-5">Available At Klang Valley, Johor Bahru, Penang, Melaka, Perak, and Pahang</h2>
            <button class="btn btn-nearyou btn-gredient" (click)="clickFindNearestGarage()">
              Find Nearest Garage
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5 near-you">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 slide-content" *ngIf="garagesNearBy && garagesNearBy.length">
          <h2 class="text-center like-h1">Find Nearest Garage</h2>
          <ul class="garage-card mt-4" id="car-carousell-list">
            <li *ngFor="let item of garagesNearBy" (click)="onChooseGarage(item)" class="clickable">
              <img src="{{ item.imageUrl || item.desktopImageUrl}}" alt="Garage Banner" width="271" height="244"
                loading="lazy" class="d-lg-none d-block" />
              <img src="{{ item.desktopImageUrl ||  item.imageUrl}}" alt="Garage Banner" width="271" height="244"
                loading="lazy" class="d-lg-block d-none" />
              <strong class="mt-2">{{ item.name }}</strong>
              <p class="provider-service">{{ item.providedServices }}</p>
              <span>{{ item.distance }}KM</span>
            </li>
          </ul>
          <div class="pre-next" style="font-size: 18px" *ngIf="garagesNearBy && garagesNearBy.length">
            <a (click)="pre()">
              <span><i class="fa fa-angle-left"></i></span>
            </a>
            <a (click)="next()">
              <span><i class="fa fa-angle-right"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-5 voice-satisfaction">
    <div class="container py-5">
      <div class="text-center title">
        <h2 class="mb-0 like-h1">Voices Of Satisfaction</h2>
        <p>Testimonials From Happy Customers</p>
      </div>
      <div class="rating-item">
        <ul class="d-flex">
          <li *ngFor="let item of dataRating" class="mr-2">
            <div class="card-rating p15">
              <div class="rating-star mt-3">
                <i class="fa fa-star mr-1" *ngFor="let item of [].constructor(item.rate)"></i>
              </div>
              <div class="my-3 comment">
                <p class="mb-0">
                  {{item.comment}}
                </p>
              </div>
              <div class="sign">
                <span class="d-block">{{item.sign}}</span>
                <span>{{item.created | date:'dd MMM yyyy'}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="wcu" id="whygogarage" #whygogarage>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-12 bg-white-trans">
          <div class="s-title mt-5 p30">
            <h1>Why GoCar Garage?</h1>
            <p>
              GoCar Garage provides a digital one-stop solution for all your car maintenance,
              repair and accessory needs - especially for off-warranty cars.
            </p>
          </div>
          <ul>
            <li class="mt-4">
              <h3>Convenient</h3>
              <p>
                Get your car picked up, serviced and dropped off with GoValet
                service. <i>*Available for specific locations only.</i>
              </p>
              <img src="../assets/images/img-wcu01.jpg" alt="Convenient" />
            </li>
            <li class="mt-4">
              <h3>Reliable</h3>
              <p>Experience quality lubricants and parts from our trusted partner Garages.</p>
              <img src="../assets/images/img-wcu02.jpg" alt="Reliable" />
            </li>
            <li class="mt-4">
              <h3>Flexible</h3>
              <p>
                Enjoy paying in installments with your favourite payment services.
              </p>
              <img src="../assets/images/img-wcu03.jpg" alt="Flexible" />
            </li>
            <li class="mt-4">
              <h3>Hassle Free</h3>
              <p>
                Don't change your schedule, get 2 hours of GoCar Sharing while your car is being serviced.
                <i>*Available for specific locations only, terms apply.</i>
              </p>
              <img src="../assets/images/img-wcu04.jpg" alt="Hassle Free" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="our-services p30">
    <div class="s-title">
      <h2 class="like-h1">Our Services</h2>
      <p>
        We offer a wide range of services, available for any make and model.
      </p>
    </div>
    <div class="container">
      <div class="row mt-5 s-logo">
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/toyota.jpg" alt="Toyota" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/honda.jpg" alt="Honda" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/hyundai.jpg" alt="Hyundai" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/kia.jpg" alt="KIA" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/merce.jpg" alt="Mercedes" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/mitsubishi.jpg" alt="Mitsubishi" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/nissan.jpg" alt="Nissan" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/perodua.jpg" alt="Perodua" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/pregeot.jpg" alt="Pregeot" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/proton.jpg" alt="Proton" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/bmw.jpg" alt="BMW" />
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="../assets/images/logo-services/volvo.jpg" alt="Volvo" />
        </div>
      </div>
    </div>
  </section>

  <section class="talk-expert">
    <div class="container">
      <div class="row d-flex flex-wrap-reverse">
        <div class="col-lg-4 col-md-4 order-lg-1">
          <img src="../assets/images/expert.png" alt="Speak to an expert now" />
        </div>
        <div class="col-lg-8 col-md-8 order-lg-2 align-self-center mb-4">
          <h3>Not sure what your car needs?</h3>
          <h2 class="like-h1">Speak to an expert now.</h2>
          <p>
            Customer Service at <strong>1300-30-2633</strong> for anything
            related to your GoCar Garage experience
          </p>
          <a routerLink="/{{ ROUTER.CONSULTATION }}" class="btn">Contact Us Now</a>
        </div>
      </div>
    </div>
  </section>
  <section id="howtobook" #howtobook>
    <div class="booking-step p30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="s-title p30">
              <h2 class="like-h1">4 Simple Steps To Service Your Car</h2>
            </div>
            <ul class="mt-4">
              <li class="row-flex">
                <img src="../assets/images/step1.jpg" alt="Select a service" />
                <span>
                  <strong>Step 1</strong>
                  <h3 style="font-size: 16px">
                    Select a service from one of our high-quality partner Garages and choose from products or packages
                    offered
                  </h3>
                </span>
              </li>
              <li class="row-flex">
                <img src="../assets/images/step2.jpg" alt="Schedule your service" />
                <span>
                  <strong>Step 2</strong>
                  <h3 style="font-size: 16px">
                    Schedule your service for either walk-in or door-to-door pick up and drop off using GoValet
                  </h3>
                </span>
              </li>
              <li class="row-flex">
                <img src="../assets/images/step3.jpg" alt="Track the status" />
                <span>
                  <strong>Step 3</strong>
                  <h3 style="font-size: 16px">
                    Track the status of your service and recieve updates on proposed work and repairs carried out
                  </h3>
                </span>
              </li>
              <li class="row-flex">
                <img src="../assets/images/step4.jpg" alt="Make payment and collect your car" />
                <span>
                  <strong>Step 4</strong>
                  <h3 style="font-size: 16px">
                    Make payment and collect your car or await delivery by GoValet. All done!
                  </h3>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--section class="talk-expert mt-5">
    <div class="container">
      <div class="row d-flex flex-wrap">
        <div class="col-lg-6 col-md-6 align-self-center text-center">
          <h3>Customer Service At</h3>
          <h2 class="like-h1">1300-30-2633</h2>
          <p>For Anything Related To Your Gocar Garage Experience:</p>
          <p>
            <strong>Mon - Fri: 7:300am - 7:30pm</strong> <br />
            <strong>Sat: 8:30am - 5:30pm</strong> <br />
            <strong>Sun & Public Holidays: 10am - 5:00pm</strong>
          </p>
          <p>Or</p>
          <p>email us at <strong>hello@gocar.my</strong></p>
        </div>
        <div class="col-lg-6 col-md-6">
          <img src="../assets/images/customer-service.png" alt="Customer Service" />
        </div>
      </div>
    </div>
  </section-->

  <section class="mt-3 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="s-title p30">
            <h2 class="like-h1">Frequetly Asked Question</h2>
          </div>

          <!-- start collapse -->
          <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
            <div class="faq-heading pb-3 pt-3" id="heading0">
              <h3 class="heading-title mb-0">
                <a href="#collapse0" role="button" data-toggle="collapse" data-parent="#faqcontainer" class="collapsed"
                  data-expanded="trye" aria-controls="collapse0">
                  Where is GoCar Garage located?
                </a>
              </h3>

            </div>
            <div id="collapse0" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading0">
              <div class="faq-body">
                <p>
                  GoCar Garage locations can be found currently in the Klang Valley, Johor Bahru, Penang and Melaka. The
                  flagship location for GoCar Garage is GoCar Garage (PJ) found in Section 13, Petaling Jaya. Download
                  the GoCar App to browse our network of independent and authorised Garages and find the right Garage
                  for your needs.
                </p>
              </div>
            </div>
          </div>

          <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
            <div class="faq-heading pb-3 pt-3" id="heading1">
              <h3 class="heading-title mb-0">
                <a href="#collapse1" role="button" data-toggle="collapse" data-parent="#faqcontainer" class="collapsed"
                  data-expanded="trye" aria-controls="collapse1">
                  What kinds of cars does GoCar Garage accept?
                </a>
              </h3>

            </div>
            <div id="collapse1" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading1">
              <div class="faq-body">
                <p>
                  GoCar Garage accepts all makes and models of passenger vehicles from local, Asian and continental
                  brands. However, commercial vehicles are not currently serviced by GoCar Garage with the exception of
                  pickup trucks.
                </p>
                <p>
                  That being said, please ensure that your chosen Garage accepts your car as some specialist Garages
                  only accept single brands or a selection of a few brands.
                </p>
                <p>
                  NOTE: Do not send your car to any Garage outside the specified authorised network If your vehicle is
                  under warranty and you intend to maintain the provided warranty
                </p>
              </div>
            </div>
          </div>

          <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
            <div class="faq-heading pb-3 pt-3" id="heading2">
              <h3 class="heading-title mb-0">
                <a href="#collapse2" role="button" data-toggle="collapse" data-parent="#faqcontainer" class="collapsed"
                  data-expanded="trye" aria-controls="collapse2">
                  How are GoCar Garage partners selected?
                </a>
              </h3>

            </div>
            <div id="collapse2" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading2">
              <div class="faq-body">
                <p>
                  Garage partners are evaluated and selected based on several criteria, including a high standard of
                  technical capability and customer service, location, pricing, quality of work and range of services
                  offered.
                </p>
                <p>
                  GoCar Garage customers can have complete peace of mind knowing that their experience will be
                  consistent with high-quality services at reasonable prices regardless of the location they choose.
                </p>
              </div>
            </div>
          </div>

          <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
            <div class="faq-heading pb-3 pt-3" id="heading3">
              <h3 class="heading-title mb-0">
                <a href="#collapse3" role="button" data-toggle="collapse" data-parent="#faqcontainer" class="collapsed"
                  data-expanded="trye" aria-controls="collapse3">
                  What kind of products and services can I find on GoCar Garage?
                </a>
              </h3>

            </div>
            <div id="collapse3" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading3">
              <div class="faq-body">
                <p>
                  Along with periodic oil change maintenance, GoCar Garage offers an array of repair, replacement and
                  rectification services in the areas of tyres, brakes, batteries, body & paint and air-conditioning as
                  well as mechanical and electrical systems. Customers can also enjoy a range of non-repair services
                  such as performance tuning, tinting, detailing and paint coating.
                </p>
              </div>
            </div>
          </div>

          <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
            <div class="faq-heading pb-3 pt-3" id="heading4">
              <h3 class="heading-title mb-0">
                <a href="#collapse4" role="button" data-toggle="collapse" data-parent="#faqcontainer" class="collapsed"
                  data-expanded="trye" aria-controls="collapse4">
                  Why should I use GoCar Garage?
                </a>
              </h3>

            </div>
            <div id="collapse4" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading4">
              <div class="faq-body">
                <p>
                  GoCar Garage is intended to provide complete peace of mind to car owners who are looking to service,
                  repair, upgrade or care for their cars, offering high-quality products and services at reasonable
                  prices through approved Garages. GoCar Garage’s oil change service is attractively priced, and starts
                  from just RM98.
                </p>
                <p>
                  Besides this, customers have the assurance of complete transparency as they can pre-approve quotations
                  on all jobs before GoCar Garage technicians begin work. This pre-approval process ensures there will
                  not be any hidden charges or surprise costs.
                </p>

              </div>
            </div>
          </div>
          <!-- end collapse  -->

          <div class="align-items-center d-flex justify-content-center mt-3">
            <button class="btn btn-gredient" (click)="onClickFindAllFAQ()">
              Find All FAQ
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</app-main-layout>
<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>
<app-modal-login [modalId]="modalLogin" [redirectUrl]="redirectUrl"></app-modal-login>
<app-modal-select-date-time modalId="mocal-calander-date-time" (choose)="confirmBookAppointment($event)">
</app-modal-select-date-time>