import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingsService } from 'src/app/shared/services/bookings/bookings.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-calendar-auth-callback',
  templateUrl: './calendar-auth-callback.component.html',
  styleUrls: ['./calendar-auth-callback.component.scss']
})
export class CalendarAuthCallbackComponent implements OnInit {
  isLoading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingsService,
    private utilsService: UtilsService
  ) {
    this.route.queryParams.subscribe((params: any) => {
      const code = params && params.code
      if (code) {
        this.insertCalendar(code)
      } else {
        window.close()
      }
    })
  }

  async insertCalendar(code: string) {
    try {
      let data: any = this.utilsService.getLocalStorage('calendar-auth')
      data = JSON.parse(data as string);
      const result = await this.bookingService.insertCalendar({ code, bookingId: data && data.bookingId }).toPromise()
      setTimeout(() => {
        localStorage.removeItem('calendar-auth')
        window.location.href = 'https://calendar.google.com'
      }, 1000);
    } catch (error) {
      window.close()
    }
  }

  ngOnInit(): void {
  }

}
