import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UtilsService } from './services/utils/utils.service';
import { CategoriesService } from './services/categories/categories.service';
import { GaragesService } from './services/garages/garages.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { PricingFormatPipe } from './pipe/pricing-format.pipe';
import { ModalConfirmCommonComponent } from './components/modal-confirm-common/modal-confirm-common.component';
import { DateTimeFormatPipe } from './pipe/date-time-format.pipe';
import { ModalCalanderComponent } from './components/modal-calander/modal-calander.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { UsersService } from './services/users/users.service';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { NgxLoadingModule } from 'ngx-loading';
import { BookingsService } from './services/bookings/bookings.service';
import { VouchersService } from './services/vouchers/vouchers.service';
import { ConfigsService } from './services/configs/configs.service';
import { ModalQuotationComponent } from './components/modal-quotation/modal-quotation.component';
import { ModalInvoiceComponent } from './components/modal-invoice/modal-invoice.component';
import { GoogleServiceProvider } from './services/google-service/google-service';
import { ModalViewServiceGalleryComponent } from './components/modal-view-service-gallery/modal-view-service-gallery.component';
import { ModalViewGarageDetailComponent } from './components/modal-view-garage-detail/modal-view-garage-detail.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { ModalViewShoppingCartComponent } from './components/modal-view-shopping-cart/modal-view-shopping-cart.component';
import { ModalViewServiceDetailComponent } from './components/modal-view-service-detail/modal-view-service-detail.component';
import { ProductsService } from './services/products/products.service';
import { CarsService } from './services/cars/cars.service';
import { RouterModule } from '@angular/router';
import { PromotionsService } from './services/promotions/promotions.service';
import { ModalSelectDateTimeComponent } from './components/modal-select-date-time/modal-select-date-time.component';
import { OnlynumberDirective } from './directives/only-number.directive';
import { GocarDialogService } from './services/gocar-dialog/gocar-dialog.service';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { LiveChatService } from './services/live-chat.service';
import { ModalIframeComponent } from './components/modal-iframe/modal-iframe.component';
import { SafeResourceUrlPipe } from './pipe/safe-resource-url.pipe';

@NgModule({
  declarations: [
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    MainLayoutComponent,
    PricingFormatPipe,
    ModalConfirmCommonComponent,
    DateTimeFormatPipe,
    ModalCalanderComponent,
    ModalLoginComponent,
    ModalQuotationComponent,
    ModalInvoiceComponent,
    ModalViewServiceGalleryComponent,
    ModalViewGarageDetailComponent,
    NavMenuComponent,
    ModalViewShoppingCartComponent,
    ModalViewServiceDetailComponent,
    ModalSelectDateTimeComponent,
    OnlynumberDirective,
    ModalIframeComponent,
    SafeResourceUrlPipe
  ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    RouterModule,
    DynamicDialogModule
  ],
  providers: [
    UtilsService,
    CategoriesService,
    GaragesService,
    DecimalPipe,
    UsersService,
    BookingsService,
    VouchersService,
    ProductsService,
    CarsService,
    ConfigsService,
    GoogleServiceProvider,
    PromotionsService,
    GocarDialogService,
    DialogService,
    LiveChatService
  ],
  exports: [
    MainLayoutComponent,
    ModalConfirmCommonComponent,
    PricingFormatPipe,
    DateTimeFormatPipe,
    ModalCalanderComponent,
    ModalLoginComponent,
    ModalQuotationComponent,
    ModalInvoiceComponent,
    ModalViewServiceGalleryComponent,
    ModalViewGarageDetailComponent,
    NavMenuComponent,
    ModalViewShoppingCartComponent,
    ModalViewServiceDetailComponent,
    ModalSelectDateTimeComponent,
    OnlynumberDirective,
    ModalIframeComponent,
    SafeResourceUrlPipe
  ]
})
export class SharedModule { }
