import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as config } from "src/environments/environment";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigsService extends BaseService {
  prefixUR = 'config';

  constructor(
    public override http: HttpClient
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getBanners() {
    return this.get(`${this.prefixUR}/banners`);
  }

}
