<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content p-3">
            <div class="text-right">
                <i class="fa fa-times clickable" aria-hidden="true" style="color: #4C4C4C; font-size: 20px;"
                (click)="cancel()"></i>
            </div>
            <div class="row justify-content-center fs-16-18">
                <div class="col-11 mb-3 text-center">
                    <strong>
                        Mind sharing your thoughts on our app service? <br class="d-none d-md-block"> Your feedback
                        helps us improve!
                    </strong>
                </div>
                <div class="col-12 text-center mb-2">
                    <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(1)" (mouseout)="resetStars()"
                        (click)="setRating(1)"></i>
                    <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(2)" (mouseout)="resetStars()"
                        (click)="setRating(2)"></i>
                    <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(3)" (mouseout)="resetStars()"
                        (click)="setRating(3)"></i>
                    <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(4)" (mouseout)="resetStars()"
                        (click)="setRating(4)"></i>
                    <i class="star fa fa-star fa-2x" (mouseover)="highlightPreviousStars(5)" (mouseout)="resetStars()"
                        (click)="setRating(5)"></i>
                </div>
                <div class="mt-3 col-12 px-0 comment">
                    <textarea class="form-control text-center pt-3" [(ngModel)]="comment" rows="5" placeholder="Add your Comments here"></textarea>
                </div>

                <div class="col-12 mt-3">
                    <button class="btn btn-submit col-12" (click)="submit()">Submit</button>
                    <span class="btn-skip" (click)="cancel()">Skip</span>
                </div>
            </div>
        </div>
    </div>
    <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>
</div>