import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/app/shared/common/constants';
import { UsersService } from '../../services/users/users.service';
import { UtilsService } from '../../services/utils/utils.service';
import * as _ from 'lodash';
import { APP_ROUTES } from '../../common/app-routes';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menus = CONSTANTS.MENU;
  modalLogin = 'modal-login-header';
  modalLogout = 'modal-confirm-logout';
  userLogin: any;

  @Input()
  isFullWidth = false

  constructor(
    private usersService: UsersService,
    private utilsServce: UtilsService,
    private router: Router
  ) {
    this.usersService.userLogin.subscribe(user => {
      if (!user) {
        this.menus = _.filter(this.menus, item => item.name !== 'My Booking');
        return;
      }
      
      const existItems = _.find(this.menus, item => item.name == 'My Booking');

      if (existItems) {
        return;
      }

      this.menus.push({
        name: 'My Booking',
        href: APP_ROUTES.HISTORY
      })
    });
  }

  ngOnInit(): void {
  }

  onClickLogin() {

    const userLogin = this.usersService.getUserLocal();
    if (userLogin) {
      return this.utilsServce.showError('User Login already!');
    }

    this.showModal(this.modalLogin);
    
  }

  onClickLogout() {
    this.showModal(this.modalLogout);
  }

  confirmLogout(option: any) {
    if (option != 'ok') {
      return;
    }

    this.usersService.logout();

    const url = this.router.url;

    if (url && url.includes(`/${APP_ROUTES.HISTORY}`)) {
      this.router.navigate([APP_ROUTES.HOME]);
    }
  }

   // PRIVATE FUNCTIONS
  private showModal(modalId: String) {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }
}
