<div style="background-color: #fafafa;">
    <!-- nav bar -->
    <section>
        <nav class="text-right p-2">
            <a class="btn btn-outline mr-2 py-1" *ngIf="usersService.getUserLocal()" (click)="onClickMyBooking()">
                <span style="font-weight: 550; font-size: 12px;">My Booking</span>
            </a>
            <a class="btn btn-outline mr-2 py-1" href="https://blog.gocargarage.my" target='_blank'>
                <span style="font-weight: 550; font-size: 12px">Blog</span>
            </a>
            <button class="btn btn-confirm py-1" *ngIf="!usersService.getUserLocal()" (click)="onClickLogin()">
                <span style="font-weight: 550; font-size: 12px">Login</span>
                <i class="fas fa-sign-in-alt ml-2"></i>
            </button>
            <button class="btn btn-confirm py-1" *ngIf="usersService.getUserLocal()" (click)="onClickLogout()">
                <span style="font-weight: 550; font-size: 12px">Logout</span>
                <i class="fas fa-sign-out-alt ml-2"></i>
            </button>
        </nav>
        <hr class="m-0">
    </section>

    <!-- banner -->
    <!-- banner -->
    <section class="garage-banner">
        <div class="row mx-0 justify-content-center">
            <div id="carouselExampleControls" class="carousel slide col-md-12 p-0" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleControls" *ngFor="let item of banners; let i = index"
                        [attr.data-slide-to]="i" class="active"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="h-100 w-100 carousel-item {{ i == 0 ? 'active' : '' }}"
                        *ngFor="let item of banners; let i = index">
                        <img src="{{ item.imageUrl || item.desktopImageUrl}}" class="d-block clickable banner-image"
                            alt="Banner" (click)="onSelecteSlide(item)" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls"
                    data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-target="#carouselExampleControls"
                    data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </button>
            </div>
        </div>
    </section>

    <!-- category -->
    <section class="p-3">
        <div class="row mx-0">
            <div class="col-3 p-2" *ngFor="let item of categories" (click)="onSelectCategory(item)">
                <div class="bg-white text-center px-3 py-4" style="border-radius: 10px;">
                    <img src="{{ item.website_image }}" alt="{{ item.name }}" width="85%" />
                </div>
                <div class="text-center mt-2">
                    <strong class="sm-text">{{ item.name }}</strong>
                </div>
            </div>
        </div>
    </section>

    <!-- car near you -->
    <section class="p-4" *ngIf="garagesNearBy && garagesNearBy.length">
        <div class="garage-box">
            <div class="title">
                <strong class="description gtitle">Garages near you</strong>
            </div>
            <div class="garage-wrapper slide-content">
                <div class="garage-item slide-item item-card" *ngFor="let item of garagesNearBy"
                    (click)="onChooseGarage(item)">
                    <div [ngStyle]="{'background': 'url(' + item.imageUrl + ') no-repeat center', 'background-size': 'cover'}"
                        class="garage-thumb"></div>
                    <span class="garage-name ml-2">{{item.name}}</span>
                    <span class="garage-address ml-2">{{item.providedServices}}</span>
                    <span class="garage-distance ml-2">{{item.distance}}km </span>
                </div>
            </div>
        </div>
    </section>

    <!-- talk expert -->
    <section class="talk-expert-landing">
        <div class="row mx-0">
            <div class="col-5" style="position: relative;">
                <img src="../assets/images/expert.png" alt="Speak to an expert now"  style="position: absolute; bottom: 0; width: 80%;"/>
            </div>
            <div class="col-7 text-center" style="font-size: 12px; padding: 15px 0;">
                <div>Not sure what your car needs?<br>
                    <strong>Speak to an expert now.</strong><br>
                    Customer Service at <br>
                    <strong>1300-30-2633</strong><br>
                    <a routerLink="/{{ ROUTER.CONSULTATION }}" class="btn">Contact Us Now</a>
                </div>
            </div>
        </div>
    </section>

    <!-- Question -->
    <section class="p-4">
        <div class="row">
            <div class="col-12 sm-text">
                <div class="">
                    <div><strong>Frequetly Asked Question</strong></div>
                </div>

                <!-- start collapse -->
                <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
                    <div class="faq-heading pb-3 pt-3" id="heading0">
                        <div class="heading-title mb-0">
                            <a href="#collapse0" role="button" data-toggle="collapse" data-parent="#faqcontainer"
                                class="collapsed" data-expanded="trye" aria-controls="collapse0">
                                Where is GoCar Garage located?
                            </a>
                        </div>

                    </div>
                    <div id="collapse0" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading0">
                        <div class="faq-body">
                            <p>
                                GoCar Garage locations can be found currently in the Klang Valley, Johor Bahru, Penang
                                and Melaka. The
                                flagship location for GoCar Garage is GoCar Garage (PJ) found in Section 13, Petaling
                                Jaya. Download
                                the GoCar App to browse our network of independent and authorised Garages and find the
                                right Garage
                                for your needs.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
                    <div class="faq-heading pb-3 pt-3" id="heading1">
                        <div class="heading-title mb-0">
                            <a href="#collapse1" role="button" data-toggle="collapse" data-parent="#faqcontainer"
                                class="collapsed" data-expanded="trye" aria-controls="collapse1">
                                What kinds of cars does GoCar Garage accept?
                            </a>
                        </div>
                    </div>
                    <div id="collapse1" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading1">
                        <div class="faq-body">
                            <p>
                                GoCar Garage accepts all makes and models of passenger vehicles from local, Asian and
                                continental
                                brands. However, commercial vehicles are not currently serviced by GoCar Garage with the
                                exception of
                                pickup trucks.
                            </p>
                            <p>
                                That being said, please ensure that your chosen Garage accepts your car as some
                                specialist Garages
                                only accept single brands or a selection of a few brands.
                            </p>
                            <p>
                                NOTE: Do not send your car to any Garage outside the specified authorised network If
                                your vehicle is
                                under warranty and you intend to maintain the provided warranty
                            </p>
                        </div>
                    </div>
                </div>

                <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
                    <div class="faq-heading pb-3 pt-3" id="heading2">
                        <div class="heading-title mb-0">
                            <a href="#collapse2" role="button" data-toggle="collapse" data-parent="#faqcontainer"
                                class="collapsed" data-expanded="trye" aria-controls="collapse2">
                                How are GoCar Garage partners selected?
                            </a>
                        </div>
                    </div>
                    <div id="collapse2" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading2">
                        <div class="faq-body">
                            <p>
                                Garage partners are evaluated and selected based on several criteria, including a high
                                standard of
                                technical capability and customer service, location, pricing, quality of work and range
                                of services
                                offered.
                            </p>
                            <p>
                                GoCar Garage customers can have complete peace of mind knowing that their experience
                                will be
                                consistent with high-quality services at reasonable prices regardless of the location
                                they choose.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
                    <div class="faq-heading pb-3 pt-3" id="heading3">
                        <div class="heading-title mb-0">
                            <a href="#collapse3" role="button" data-toggle="collapse" data-parent="#faqcontainer"
                                class="collapsed" data-expanded="trye" aria-controls="collapse3">
                                What kind of products and services can I find on GoCar Garage?
                            </a>
                        </div>
                    </div>
                    <div id="collapse3" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading3">
                        <div class="faq-body">
                            <p>
                                Along with periodic oil change maintenance, GoCar Garage offers an array of repair,
                                replacement and
                                rectification services in the areas of tyres, brakes, batteries, body & paint and
                                air-conditioning as
                                well as mechanical and electrical systems. Customers can also enjoy a range of
                                non-repair services
                                such as performance tuning, tinting, detailing and paint coating.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="faq-container" id="faqcontainer" role="tablist" aria-multiselectable="true">
                    <div class="faq-heading pb-3 pt-3" id="heading4">
                        <div class="heading-title mb-0">
                            <a href="#collapse4" role="button" data-toggle="collapse" data-parent="#faqcontainer"
                                class="collapsed" data-expanded="trye" aria-controls="collapse4">
                                Why should I use GoCar Garage?
                            </a>
                        </div>
                    </div>
                    <div id="collapse4" class="faq-collapse collapse mt-3" role="tabpanel" aria-labelledby="heading4">
                        <div class="faq-body">
                            <p>
                                GoCar Garage is intended to provide complete peace of mind to car owners who are looking
                                to service,
                                repair, upgrade or care for their cars, offering high-quality products and services at
                                reasonable
                                prices through approved Garages. GoCar Garage’s oil change service is attractively
                                priced, and starts
                                from just RM98.
                            </p>
                            <p>
                                Besides this, customers have the assurance of complete transparency as they can
                                pre-approve quotations
                                on all jobs before GoCar Garage technicians begin work. This pre-approval process
                                ensures there will
                                not be any hidden charges or surprise costs.
                            </p>

                        </div>
                    </div>
                </div>
                <!-- end collapse  -->

                <div class="align-items-center d-flex justify-content-center mt-3">
                    <button class="btn btn-gredient" (click)="onClickFindAllFAQ()">
                        Find All FAQ
                    </button>
                </div>
            </div>
        </div>
    </section>
</div>
<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>
<app-modal-login [modalId]="modalLogin"></app-modal-login>
<app-modal-confirm-common [modalId]="modalLogout" (choose)="confirmLogout($event)"
    confirmContent="Are you want to logout?">
</app-modal-confirm-common>