<nav class="navbar navbar-expand-lg navbar-light bg-white header">
  <div class="container nav-menu {{isFullWidth ? 'nav-menu-full-width' : ''}}">
    <!-- logo show mobile only -->
    <a class="navbar-brand" routerLink="/">
      <img src="./assets/images/img-logo.png" width="124" height="58" alt="GoCar Logo" />
    </a>
    <app-nav-menu [menus]="menus" (clickLogin)="onClickLogin()" (clickLogout)="onClickLogout()">
    </app-nav-menu>
  </div>
</nav>

<app-modal-login modalId="modal-login-header"></app-modal-login>
<app-modal-confirm-common modalId="modal-confirm-logout" (choose)="confirmLogout($event)"
  confirmContent="Are you want to logout?">
</app-modal-confirm-common>