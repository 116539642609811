<div class="modal" [id]="modalId" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="content">
                <span class="btn-close">
                    <i class="fa fa-times" aria-hidden="true" (click)="hide(null)"></i>
                </span>
                <div class="row">
                    <div class="header-section">
                        <strong>
                            Service Gallery
                        </strong>

                        <hr class="header-line" />
                    </div>
                    <div class="content-section">
                        <div *ngIf="data && data.inspections && data.inspections.length">
                            <strong>Inspection Photos</strong>
                            <div class="images">
                                <div class="{{ photo.attachmentType != 'video' ? 'col-6' : 'col-12'}} col-lg-3 mt-2"
                                    *ngFor="let photo of data.inspections">
                                    <!-- <a href="{{photo.url}}" target="_blank">
                                        <img class="img-item" [src]="photo.url" loading="lazy" alt="Inspection Photo">
                                    </a> -->
                                    <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType != 'video'">
                                        <img class="img-item" [src]="photo.url" loading="lazy" alt="Inspection Photo">
                                    </a>
                                    <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType == 'video'">
                                        <video controls width="100%">
                                            <source [src]="photo.url">
                                        </video>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5" *ngIf="data && data.repairs && data.repairs.length">
                            <strong>Repair Photos</strong>
                            <div class="images">
                                <div class="{{ photo.attachmentType != 'video' ? 'col-6' : 'col-12'}} col-lg-3 mt-2" *ngFor="let photo of data.repairs">
                                    <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType != 'video'">
                                        <img class="img-item" [src]="photo.url" loading="lazy" alt="Repair Photo">
                                    </a>
                                    <a href="{{photo.url}}" target="_blank" *ngIf="photo.attachmentType == 'video'">
                                        <video controls width="100%">
                                            <source [src]="photo.url">
                                        </video>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true  }"></ngx-loading>