import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'
import { Observable, map, catchError, throwError, BehaviorSubject } from 'rxjs';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {
  public userLoginSubject = new BehaviorSubject<any>({});
  public userLogin: Observable<any>;

  constructor(
    public override http: HttpClient,
    private utilsService: UtilsService
  ) {
    super(http);
    this.apiURL = config.gogarage_api;

    let data = this.getUserLocal()
    this.userLoginSubject = new BehaviorSubject<any>(data);
    this.userLogin = this.userLoginSubject.asObservable();
  }

  public get userValue() {
    return this.userLoginSubject.value;
  }

  requestOTP(phoneNumber: string, reCaptchaToken: string) {
    return this.post(`auth/request-otp`, { phoneNumber, reCaptchaToken });
  }

  verifyOTP(phoneNumber: string, otpNumber: string): Observable<any> {
    let authHeader = { 'gocar-authentication': `${phoneNumber}:${otpNumber}` };
    return this.post(`auth/verify-otp`, null, authHeader).pipe(
      map(this.handleAuthResponse.bind(this)),
      catchError(error => throwError(error))
    )
  }

  private handleAuthResponse(res: any): object {
    let returndata = res['data'];
    let token = returndata.token;
    this.utilsService.setLocalStorage('user_token', token);

    // save basic info on localstorage
    let userBasicInfo = {
      userId: returndata.id,
      firstName: returndata.firstName,
      lastName: returndata.lastName,
      email: returndata.email,
      phoneNumber: returndata.phoneNumber,
    }
    this.utilsService.setLocalStorage('user_info', JSON.stringify(userBasicInfo));
    this.utilsService.setLocalStorage('login_info', JSON.stringify({ loginAt: new Date().getTime() }));

    this.userLoginSubject.next(userBasicInfo);
    return userBasicInfo;
  }

  getUserLocal() {
    let data = this.utilsService.getLocalStorage('user_info');


    if (data) {
      return JSON.parse(data);
    }
  }

  getLoginLocal() {
    let data = this.utilsService.getLocalStorage('login_info');

    if (data) {
      return JSON.parse(data);
    }
  }

  logout() {
    this.utilsService.setLocalStorage('user_info', '');
    this.utilsService.setLocalStorage('login_info', '');
    this.utilsService.setLocalStorage('user_token', '');

    this.userLoginSubject.next(null);
  }
}
