import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CarsService extends BaseService {
  prefixURL = 'cars';

  constructor(
    public override http: HttpClient
  ) {
    super(http);
    this.apiURL = config.gogarage_api;
  }

  getListCarType() {
    return this.get(`${this.prefixURL}/car-type`);
  }
}
