import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as _ from 'lodash'
declare var $: any;

@Component({
  selector: 'app-modal-view-service-detail',
  templateUrl: './modal-view-service-detail.component.html',
  styleUrls: ['./modal-view-service-detail.component.scss']
})
export class ModalViewServiceDetailComponent implements OnInit, OnChanges {
  @Input() modalId = '';
  @Input() confirmContent = '';
  @Input() data: any;
  @Output() choose = new EventEmitter<any>();

  products: any = []

  constructor() { }

  ngOnInit() {}

  ngOnChanges(): void {
    this.products = [];

    if (this.data && this.data.products) {
      let productsGroupById = _.groupBy(this.data.products, 'productId')
      for (let key in productsGroupById) {
        let product = productsGroupById[key][0]
        product.count = productsGroupById[key].length
        this.products.push(product)
      }
    }
  }

  hide(option: any) {
    $(`#${this.modalId}`).modal("hide");
    this.data = undefined;
    this.choose.emit(option == 'ok' ? this.data || option : '');
  }
}
