import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from '../../common/app-routes';
import { CONSTANTS } from '../../common/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  DOWNLOAD = CONSTANTS.DOWNLOAD
  APP_ROUTES = APP_ROUTES
  constructor() { }

  ngOnInit(): void {
  }

}
