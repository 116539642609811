
export const APP_ROUTES = {
    HOME: '/',
    CONSULTATION: 'consultation',
    NOT_FOUND: 'not-found',
    GARAGE: 'garages',
    HISTORY: 'my-booking',
    PAYMENT_SUCCESS: 'payment-result-success',
    PAYMENT_FAILED: 'payment-result-failed',
    SERVICE_HISTORY: 'service-history',
    RATING: 'rating',
    CALENDAR_AUTH_CALLBACK: 'oauth2callback',
}