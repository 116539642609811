import { Injectable } from '@angular/core';
import { unset } from 'lodash';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class GocarDialogService {

  /*
  * https://www.primefaces.org/primeng-v13/dynamicdialog
  */

  constructor(private dialogService: DialogService) { }

  show<T>(component: any, option?: DynamicDialogConfig): Promise<T> {

    let optionDefault = {
      data: option?.data || {},
      header: option?.header || 'Header',
      width: option?.width || '50%',
      contentStyle: option?.contentStyle || { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000,
      style: {
        'border': 'unset',
        'font-size': '14px',
        'color': '#343434',
        'font-family': 'Montserrat, sans-serif',
        'border-radius': '50px'
      },
      showHeader: option?.showHeader,
      closeOnEscape: option?.closeOnEscape,
      styleClass: option?.styleClass
    }

    const ref = this.dialogService.open(component, optionDefault);

    return new Promise(async (resolve, reject) => {
      ref.onClose.subscribe((data: T) => {
        resolve(data)
      });
    })
  }
}
