import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../..//environments/environment';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class BookingsService extends BaseService {

  constructor(public override http: HttpClient, public utilsService: UtilsService) {
    super(http, utilsService);
    this.apiURL = config.gogarage_api;
  }

  create(body: any) {
    return this.post('bookings', body);
  }

  insertCalendar(body: any) {
    return this.post('bookings/insert-calendar-event', body);
  }

  getBookingHistory(params: any) {
    return this.get('bookings', params);
  }

  getPaymentMethod() {
    return this.get('bookings/get-payment-method');
  }

  getBookingById(id: string) {
    return this.get(`bookings/${id}`);
  }

  calculatePriceBooking(body: any) {
    return this.post(`bookings/calculate-price-booking`, body);
  }

  generateHoldPayment(body: any) {
    return this.post(`bookings/generate-hold-payment`, body);
  }

  getLatestBooking() {
    return this.get(`bookings/latest`);
  }

  approveOrDisapproveQuotation(bookingId: string, quotationId: string, isApproved: boolean) {
    return this.put(`bookings/${bookingId}/quotations/${quotationId}`, { isApproved });
  }

  updateGoGarageBookingStatus(bookingId: string, status: string) {
    return this.post(`bookings/${bookingId}/status`, { status: status })
  }

  estimateGovaletFee(body: any) {
    return this.post('bookings/estimate-govalet-fee', body);
  }

  submitRating(body: any) {
    return this.post('bookings/rating', body);
  }
}
